@font-face {
  font-family: 'NoirPro';
  src: url('noir-pro/NoirPro-Light.eot?#iefix') format('embedded-opentype'), url('noir-pro/NoirPro-Light.woff') format('woff'), url('noir-pro/NoirPro-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'NoirPro';
  src: url('noir-pro/NoirPro-Regular.eot?#iefix') format('embedded-opentype'), url('noir-pro/NoirPro-Regular.woff') format('woff'), url('noir-pro/NoirPro-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'NoirPro';
  src: url('noir-pro/NoirPro-Medium.eot?#iefix') format('embedded-opentype'), url('noir-pro/NoirPro-Medium.woff') format('woff'), url('noir-pro/NoirPro-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'NoirPro';
  src: url('noir-pro/NoirPro-SemiBold.eot?#iefix') format('embedded-opentype'), url('noir-pro/NoirPro-SemiBold.woff') format('woff'), url('noir-pro/NoirPro-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'NoirPro';
  src: url('noir-pro/NoirPro-Bold.eot?#iefix') format('embedded-opentype'), url('noir-pro/NoirPro-Bold.woff') format('woff'), url('noir-pro/NoirPro-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'NoirPro';
  src: url('noir-pro/NoirPro-Heavy.eot?#iefix') format('embedded-opentype'), url('noir-pro/NoirPro-Heavy.woff') format('woff'), url('noir-pro/NoirPro-Heavy.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}
/* Content Layout Style */
.app-layout {
  font-family: 'NoirPro', sans-serif;
  background: #f5f5f5;
}
.app-layout .content-layout {
  height: 100vh;
}
.app-layout .content-layout > .ant-layout-content {
  overflow-x: hidden;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.app-layout .content-layout > .ant-layout-content .main-content-wrapper {
  padding: 32px 32px 0;
  flex: 1;
}
@media screen and (max-width: 480px) {
  .app-layout .content-layout > .ant-layout-content .main-content-wrapper {
    padding: 4px 4px 0;
  }
}
.app-layout .content-layout > .ant-layout-content .main-content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  background-color: #fff;
  padding: 24px;
  flex: 1;
  height: 100%;
  margin-bottom: 32px;
}
@media screen and (max-width: 480px) {
  .app-layout .content-layout > .ant-layout-content .main-content {
    padding: 5px;
  }
}
.app-layout .content-layout > .ant-layout-content .main-content-header {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  margin-bottom: 20px;
}
.app-layout .content-layout > .ant-layout-content .main-content-header-title {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}
.app-layout .content-layout > .ant-layout-content .main-content-info {
  line-height: 32px;
  margin-bottom: 15px;
}
/* Sidebar Style */
.ant-layout-sider {
  background-color: #fffffc;
  color: #545454;
  z-index: 11;
  flex: 0 0 auto !important;
  max-width: none !important;
  min-width: 10px !important;
  overflow: hidden;
}
.ant-layout-sider .layout-sidebar-content {
  border-right: 1px solid #e8e8e8;
  height: 100%;
}
.ant-layout-sider .layout-sidebar-content .layout-sider-scrollbar > div {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: calc(100% - 72px);
}
.ant-layout-sider-collapsed .layout-sider-header {
  padding-left: 20px;
  padding-right: 20px;
}
.ant-layout-sider-collapsed .layout-sider-header .site-logo {
  display: none;
}
.ant-layout-sider-collapsed .layout-sidebar-content .onboarding,
.ant-layout-sider-collapsed .layout-sidebar-content .changelog {
  padding: 0 32px;
  text-overflow: unset;
}
.ant-layout-sider-collapsed .layout-sidebar-content .onboarding .ant-badge-count,
.ant-layout-sider-collapsed .layout-sidebar-content .changelog .ant-badge-count,
.ant-layout-sider-collapsed .layout-sidebar-content .onboarding .text,
.ant-layout-sider-collapsed .layout-sidebar-content .changelog .text {
  display: none;
}
.ant-layout-sider.collapsed-sidebar {
  width: 0 !important;
  min-width: 0 !important;
}
.ant-layout-sider.collapsed-sidebar .layout-sider-header {
  padding-right: 25px;
}
.layout-sider-header {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.08);
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  height: 72px;
  padding: 10px 30px 10px 70px;
  position: relative;
  z-index: 1;
}
.layout-sider-header .site-logo {
  position: relative;
}
.layout-sider-header .site-logo svg {
  height: 26px;
  width: 100%;
}
.layout-sider-header .btn-linebar-menu {
  height: 40px;
  width: 40px;
  position: absolute;
  left: 20px;
  padding: 0;
  color: #545454;
  font-size: 18px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.layout-sider-header .btn-linebar-menu:hover {
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  background-color: rgba(0, 0, 0, 0.1);
  color: #545454;
}
@media screen and (max-width: 991px) {
  .layout-sider-header .btn-linebar-menu {
    display: none;
  }
}
.onboarding,
.changelog {
  text-align: left;
  width: calc(100% + 1px);
  height: 40px;
  line-height: 40px;
  padding: 0 16px 0 24px;
  overflow: hidden;
  font-size: 14px;
  text-overflow: ellipsis;
  color: #545454;
  display: block;
}
.onboarding .anticon,
.changelog .anticon {
  min-width: 14px;
  margin-right: 7px;
  font-size: 14px;
  transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.onboarding:hover,
.changelog:hover {
  color: #7f00ff !important;
}
.onboarding:hover span,
.changelog:hover span {
  color: #7f00ff !important;
}
.onboarding:hover svg,
.changelog:hover svg {
  fill: #7f00ff !important;
}
@media screen and (max-width: 991px) {
  .layout-sider-header .site-logo svg {
    height: 32px;
  }
}
@media (max-width: 768px) {
  .vt-header-wrapper .ant-drawer-content-wrapper {
    width: 77% !important;
  }
  .vt-header-wrapper .layout-sider-scrollbar > div {
    overflow: hidden !important;
  }
}
.content-layout .ant-layout-header {
  -webkit-box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.08);
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  background-color: #ffffff;
  line-height: 1;
  padding: 0 32px;
  height: 72px;
  position: relative;
  z-index: 10;
  color: #262626;
}
@media screen and (max-width: 991px) {
  .content-layout .ant-layout-header {
    padding: 0 15px;
  }
}
@media screen and (max-width: 575px) {
  .content-layout .ant-layout-header {
    height: 50px;
  }
}
.content-layout .ant-layout-header .header-notifications {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  list-style: none;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: auto !important;
}
.content-layout .ant-layout-header .header-notifications li {
  display: inline-block;
  min-width: 30px;
  height: 54px;
  line-height: 54px;
  text-align: center;
  margin: 0 4px;
}
.content-layout .ant-layout-header .header-notifications li .profile-button {
  display: block;
  padding: 4px 4px 4px 12px;
  height: 54px;
  border: none;
  box-shadow: none;
}
.content-layout .ant-layout-header .header-notifications li .profile-button:hover {
  background: rgba(0, 0, 0, 0.018);
}
.content-layout .ant-layout-header .header-notifications li .profile-button .profile-button-wrapper {
  display: flex;
  line-height: 22px;
  padding-left: 5px;
}
.content-layout .ant-layout-header .header-notifications li .profile-button .profile-button-wrapper .profile-button-text {
  display: block;
  text-align: right;
  line-height: 14px;
  margin-top: 8px;
}
.content-layout .ant-layout-header .header-notifications li .profile-button .profile-button-wrapper .profile-button-text.profile-button-company-name {
  font-size: 12px;
  margin-top: 4px;
}
.content-layout .ant-layout-header .header-notifications li .profile-button .profile-button-avatar {
  margin-left: 5px;
  width: 44px;
  height: 44px;
  line-height: 44px;
}
.header-my-profile-menu .menu-link-button {
  text-align: left;
}
.help-drawer-inner {
  padding: 12px;
  min-height: 100%;
  background: #f5f5f5;
}
.help-drawer-inner .help-drawer-card {
  margin-bottom: 12px;
}
.help-drawer-inner .ant-card-head button {
  padding: 0 !important;
}
@media screen and (max-width: 640px) {
  .content-layout .ant-layout-header .header-notifications li .profile-button {
    padding: 4px;
  }
  .content-layout .ant-layout-header .header-notifications li .profile-button .profile-button-wrapper {
    padding-left: 0;
  }
  .content-layout .ant-layout-header .header-notifications li .profile-button .profile-button-wrapper .profile-button-name-wrapper {
    display: none;
  }
  .content-layout .ant-layout-header .header-notifications li .profile-button .profile-button-wrapper .profile-button-avatar {
    margin-left: 0;
  }
}
/* Ant Menu Style */
.layout-sider-scrollbar .ant-menu {
  color: #545454;
  background: #ffffff;
}
.layout-sider-scrollbar .ant-menu-inline > .ant-menu-submenu,
.layout-sider-scrollbar .ant-menu-inline > .ant-menu-item {
  margin-inline: 0px;
  width: calc(100% - 1px);
  border-radius: 0;
}
.layout-sider-scrollbar .ant-menu-inline > .ant-menu-submenu .ant-menu-item-active .anticon svg,
.layout-sider-scrollbar .ant-menu-inline > .ant-menu-item .ant-menu-item-active .anticon svg,
.layout-sider-scrollbar .ant-menu-inline > .ant-menu-submenu .ant-menu-item-selected .anticon svg,
.layout-sider-scrollbar .ant-menu-inline > .ant-menu-item .ant-menu-item-selected .anticon svg {
  fill: #7f00ff;
}
.layout-sider-scrollbar .ant-menu-inline > .ant-menu-submenu .ant-menu-submenu-title,
.layout-sider-scrollbar .ant-menu-inline > .ant-menu-item .ant-menu-submenu-title {
  margin-inline: 0px !important;
  width: calc(100% - 1px) !important;
}
.layout-sider-scrollbar .ant-menu-inline > .ant-menu-submenu::after,
.layout-sider-scrollbar .ant-menu-inline > .ant-menu-item::after {
  border-right: 3px solid #7f00ff;
}
.layout-sider-scrollbar .ant-menu-inline-collapsed .ant-menu-submenu > .ant-menu-submenu-title,
.layout-sider-scrollbar .ant-menu-inline-collapsed .ant-menu-item {
  padding-inline: 27px !important;
}
.layout-sider-scrollbar .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #f5ebff;
}
.layout-sider-scrollbar .ant-menu-submenu:hover,
.layout-sider-scrollbar .ant-menu-item:hover,
.layout-sider-scrollbar .ant-menu-submenu-selected,
.layout-sider-scrollbar .ant-menu-item-selected,
.layout-sider-scrollbar .ant-menu-submenu-active,
.layout-sider-scrollbar .ant-menu-item-active {
  color: #7f00ff !important;
}
.layout-sider-scrollbar .ant-menu-submenu:hover > .anticon > svg,
.layout-sider-scrollbar .ant-menu-item:hover > .anticon > svg,
.layout-sider-scrollbar .ant-menu-submenu-selected > .anticon > svg,
.layout-sider-scrollbar .ant-menu-item-selected > .anticon > svg,
.layout-sider-scrollbar .ant-menu-submenu-active > .anticon > svg,
.layout-sider-scrollbar .ant-menu-item-active > .anticon > svg {
  fill: #7f00ff !important;
}
/* Footer Style */
.ant-layout-footer {
  margin-top: 32px;
}
/* Drawer Style */
.ant-drawer {
  overflow: hidden;
}
.ant-drawer-body {
  padding: 0 !important;
}
.ant-drawer-body .layout-sider-header {
  padding-left: 25px;
}
.ant-drawer-body .layout-sider-scrollbar {
  margin-top: 10px;
}
.ant-drawer-body .layout-sider-scrollbar > div {
  position: relative !important;
}
.form-layout {
  max-width: 1024px;
  margin: 40px auto 20px;
}
.form-layout .info-box {
  margin-bottom: 24px;
}
.form-layout .info-box.ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: transparent;
}
.form-layout .button-right {
  margin-left: 12px;
}
body {
  font-family: 'NoirPro', sans-serif;
}
.m-0 {
  margin: 0;
}
h4 {
  margin-top: 0;
}
.app-layout .content-layout > .ant-layout-content .main-content.form-background {
  background-color: #fdfdfd;
}
.form-background {
  background-color: #fdfdfd;
}
.display-none {
  display: none !important;
}
.d-flex {
  display: flex;
  flex-wrap: wrap;
}
.justify-content-between {
  justify-content: space-between;
}
.flex-wrap {
  flex-wrap: wrap;
}
.f-right {
  float: right;
}
@media (max-width: 1025px) {
  .f-right {
    float: none;
  }
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.vt-mt-10 {
  margin-top: 10px;
}
@media (max-width: 767px) {
  .vt-xs-mt-10 {
    margin-top: 10px;
  }
}
@media (min-width: 768px) {
  .vt-md-mt-10 {
    margin-top: 10px;
  }
}
@media (max-width: 1025px) {
  .vr-md-ml-10 {
    margin-left: 10px;
  }
}
@media (max-width: 1025px) {
  .vt-md-mr-10 {
    margin-right: 10px !important;
  }
}
@media (max-width: 1025px) {
  .vt-gap-10 {
    gap: 10px;
  }
  .vt-gap-10 button {
    margin-right: 0px !important;
  }
}
.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}
body:not(.dark-theme) .ant-tooltip-inner a {
  color: #d1a3ff;
}
body:not(.dark-theme) .ant-tooltip-inner a:hover {
  color: rgba(209, 163, 255, 0.7);
}
.center-elements-vertically {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.view-form .ant-form-item-control {
  line-height: 32px;
  font-weight: 500;
}
.info-tooltip {
  padding-left: 4px;
  padding-right: 2px;
}
.clickable-table .ant-table.ant-table-empty .ant-table-tbody .ant-table-placeholder:hover {
  box-shadow: none;
}
.clickable-table .ant-table.ant-table-empty .ant-table-tbody .ant-table-cell {
  border: none;
}
.clickable-table .ant-table table {
  border-spacing: 0 1px;
}
.clickable-table .ant-table table .ant-table-tbody .action button {
  transition: transform 0.2s;
}
.clickable-table .ant-table table .ant-table-tbody tr.ant-table-row.clickable-table-error:hover td {
  background-color: #ececec;
}
.clickable-table .ant-table table .ant-table-tbody .action button:hover {
  transition: transform 0.2s;
  transform: scale(1.3);
}
.clickable-table .ant-table table .ant-table-tbody tr:hover {
  z-index: 10;
  -webkit-box-shadow: 0px 0px 2px 0.5px rgba(127, 0, 255, 0.6);
  box-shadow: 0px 0px 2px 0.5px rgba(127, 0, 255, 0.6);
  cursor: pointer;
}
.clickable-table .clickable-table-error {
  background-color: #ececec;
  opacity: 0.6;
}
.ant-table .ant-table-container .ant-table-thead th.text-center {
  text-align: center;
}
.ant-table-wrapper.responsive-table .ant-spin-container .ant-table .ant-table-content {
  overflow-x: auto;
}
.ant-notification-notice .ant-notification-notice-description {
  white-space: break-spaces;
}
.logs-timeline {
  margin-top: 30px;
}
.logs-timeline.ant-timeline.ant-timeline-label .ant-timeline-item .ant-timeline-item-label {
  max-width: 200px;
  color: #a2a2a2;
}
.logs-timeline.ant-timeline.ant-timeline-label .ant-timeline-item .ant-timeline-item-tail {
  left: 220px;
}
.logs-timeline.ant-timeline.ant-timeline-label .ant-timeline-item .ant-timeline-item-head {
  left: 220px;
}
.logs-timeline.ant-timeline.ant-timeline-label .ant-timeline-item .ant-timeline-item-content {
  left: 220px;
  width: calc(100% - 260px);
}
.vt-primary-color {
  color: #7f00ff;
}
.center-content {
  max-width: 1280px;
  margin: 0 auto;
}
.on-top {
  z-index: 10000 !important;
}
.ant-input-emoji {
  width: 41px;
  display: inline-block;
  margin-right: 3px;
  cursor: pointer;
}
.custom-addon-after .ant-input-number-group-addon {
  border: none;
  background: none;
}
.emoji-picker {
  position: absolute;
  left: 0;
  top: 33px;
  z-index: 3;
}
.emoji-picker .emoji-mart-preview {
  height: 40px;
}
.emoji-picker .emoji-mart-preview-emoticons,
.emoji-picker .emoji-mart-preview-shortnames {
  display: none;
}
.emoji-picker .emoji-mart-preview-emoji .emoji-mart-emoji span {
  height: 24px !important;
  width: 24px !important;
}
.emoji-picker .emoji-mart-preview-data {
  left: 52px;
}
.emoji-picker .emoji-mart-preview-data .emoji-mart-preview-name {
  font-size: 12px;
}
.emoji-mart-emoji {
  top: 3px;
}
.form-inline {
  display: flex;
  flex-wrap: wrap;
  /*  added  */
  justify-content: normal;
}
.form-inline .ant-form-item {
  margin-bottom: 5px;
}
.form-inline-item {
  min-width: 100px;
}
.form-item-label-pre-wrap .ant-form-item-label {
  white-space: pre-wrap !important;
}
.invite-users-modal-header {
  background-color: lightgray;
  padding: 15px;
}
.timepicker-without-ok .ant-picker-footer {
  display: none !important;
}
.wrap-label .ant-form-item-label,
.wrap-label .ant-steps-item-title {
  white-space: normal;
}
.wrap-button-text {
  height: auto;
  white-space: normal;
}
.no-border-button {
  background-color: #ffffff;
}
@media (max-width: 768px) {
  .logs-timeline.ant-timeline.ant-timeline-label .ant-timeline-item .ant-timeline-item-label {
    max-width: 90px;
  }
  .logs-timeline.ant-timeline.ant-timeline-label .ant-timeline-item .ant-timeline-item-tail {
    left: 110px;
  }
  .logs-timeline.ant-timeline.ant-timeline-label .ant-timeline-item .ant-timeline-item-head {
    left: 1100px;
  }
  .logs-timeline.ant-timeline.ant-timeline-label .ant-timeline-item .ant-timeline-item-content {
    left: 100px;
    width: calc(100% - 140px);
  }
  .main-content-header-breadcrumb {
    display: none;
  }
  .ant-breadcrumb {
    display: none;
  }
  .content-layout .ant-layout-header {
    padding: 0 0px;
  }
  .ant-picker-panel-container .ant-picker-panels {
    overflow-x: scroll;
    width: 300px;
  }
  .vt-leave-request-additional-info {
    width: 100% !important;
  }
  .ant-select.ant-select-in-form-item {
    width: 100% !important;
  }
  .vt-span-separator {
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    height: 0.5px;
    margin-bottom: 10px;
    color: transparent;
  }
  .ant-table-content {
    overflow-x: scroll;
  }
  .ant-picker.ant-picker-range {
    width: 100%;
  }
  .ant-input {
    max-width: 100% !important;
    width: 100% !important;
  }
  .react-joyride__tooltip {
    padding: 31px 15px 15px !important;
  }
  .ant-layout-footer {
    padding: 20px 5px;
  }
  .ant-form-item {
    margin: 0 0 20px;
  }
  .ant-modal-footer {
    text-align: left;
  }
}
@media (max-width: 430px) {
  .ant-picker-panel-container .ant-picker-panels {
    overflow-x: scroll;
    width: 350px;
  }
  .vt-col-sm-24 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (max-width: 376px) {
  .ant-picker-panel-container .ant-picker-panels {
    width: 310px;
  }
}
.position-right {
  float: right;
}
.subscription-wrapper {
  margin: 0 auto;
}
.wrapper-button-vt {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 1000px) {
  .wrapper-button-vt > a {
    width: 100%;
    text-align: left;
    padding: 0;
    margin-bottom: 15px;
  }
}
.vt-approvers-name {
  color: #7f00ff;
}
.vt-edit-leave-btn {
  padding: 0px;
}
.vt-edit-leave-btn a {
  padding: 4px 15px;
}
@media (max-width: 768px) {
  .ant-picker-panels {
    display: flex;
    flex-wrap: wrap !important;
    width: 300px !important;
  }
  .ant-picker-panel:first-child .ant-picker-date-panel .ant-picker-header-super-next-btn,
  .ant-picker-panel:first-child .ant-picker-date-panel .ant-picker-header-next-btn {
    visibility: visible !important;
  }
  .ant-picker-panel:nth-child(2) .ant-picker-date-panel .ant-picker-header-super-next-btn,
  .ant-picker-panel:nth-child(2) .ant-picker-date-panel .ant-picker-header-next-btn {
    visibility: hidden !important;
  }
}
.dashboard-leaves-info .ant-card-body {
  padding-top: 0;
}
.dashboard-filters {
  display: flex;
  margin-bottom: 20px;
  justify-content: end;
}
.dashboard-width {
  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;
}
.dark-theme .dashboard-filters .ant-typography {
  color: #E0E0E0 !important;
}
@media (max-width: 768px) {
  .dashboard-filters {
    justify-content: start;
    margin-top: 20px;
  }
  .ant-card-body {
    padding: 5px;
  }
}
a.vt-auth .anticon,
button.vt-auth .anticon {
  margin-right: 8px;
}
.auth-wrap {
  height: 100vh;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  min-height: 480px;
  overflow-x: hidden;
  background-color: #fff;
}
.auth-container {
  position: relative;
  max-width: 720px;
  margin: 0 auto;
}
.auth-main-container {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-size: 14px;
  overflow: hidden;
}
.auth-sidebar {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  color: #ffffff;
  padding: 15px 35px 10px;
  border-right: 1px solid #f0f0f0;
  margin: 20px 0 10px 0;
  width: 40%;
  position: relative;
  overflow: hidden;
}
.auth-sidebar-capterra {
  text-align: center;
}
.auth-sidebar-capterra .rating-logo {
  height: 55px;
}
.auth-sidebar-logo {
  text-align: center;
  margin-bottom: 15px;
}
.auth-sidebar-logo img {
  height: 45px;
}
.auth-sidebar-content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  padding: 35px 35px 20px;
  width: 60%;
}
.auth-sidebar-content-button {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-evenly;
  -ms-justify-content: space-evenly;
  justify-content: space-evenly;
}
.auth-sidebar-content-button .anticon {
  margin-right: 8px;
}
.auth-sidebar-content-button button,
.auth-sidebar-content-button a {
  margin-bottom: 30px;
  width: 100%;
}
.auth-sidebar-content-button .google-btn {
  color: #666666;
  border-color: #666666;
  background: #ffffff;
  padding: 0px;
}
.auth-sidebar-content-button .google-btn .anticon {
  background-color: #fff;
  width: 24px;
  height: 24px;
  top: 4px;
  position: relative;
}
.auth-sidebar-content-button button:last-child {
  margin-bottom: 0;
}
.auth-sidebar-content .email-input {
  font-size: 14px;
  line-height: 25px;
}
@media screen and (max-width: 991px) {
  .create-account-title {
    margin-top: 50px;
  }
  .create-account-btn {
    width: 100%;
  }
}
@media screen and (max-width: 640px) {
  .auth-wrap {
    padding-top: 20px;
    -webkit-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
  }
  .auth-container {
    padding-bottom: 20px;
  }
  .auth-sidebar {
    width: 100%;
    padding: 20px 20px 10px;
  }
  .auth-sidebar-content {
    width: 100%;
    padding: 20px 20px 10px;
  }
}
.other-platform-exist-modal .ant-btn .anticon {
  margin-right: 8px;
}
.locations-body .btn-actions {
  margin-bottom: 10px;
}
@media (max-width: 768px) {
  .locations-body .ant-card {
    width: 100% !important;
    margin-right: 0px !important;
  }
}
.locations-body .ant-card .user-avatar {
  padding-right: 3px;
}
.locations-body .ant-card-actions .ant-btn-link {
  padding: 0;
  height: 22px;
}
.locations-body .ant-card-actions li > span a:not(.ant-card-btn) {
  color: #7f00ff;
}
.vt-location-general .ant-row {
  align-items: center;
}
@media (max-width: 768px) {
  .location-body .ant-tabs-nav .ant-tabs-extra-content {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
  }
  .location-body .vt-location-general label {
    height: auto;
  }
}
@media (max-width: 430px) {
  .vt-location-work-week-list {
    display: flex;
    flex-wrap: wrap;
  }
}
.holidays-body .ant-tabs .ant-tabs-extra-content .ant-btn {
  margin-left: 5px;
}
.holidays-body .ant-table .actions .ant-btn {
  padding: 0;
  margin: 0 5px;
  height: 22px;
  width: 22px;
  border-radius: 0;
}
.holidays-body .ant-table .actions .ant-btn .anticon {
  display: inline-block;
  width: 22px;
  height: 22px;
  line-height: 22px;
  font-size: 18px;
}
body.dark-theme .import-holidays-steps .ant-steps-item-process .ant-steps-item-title {
  color: #d1a3ff !important;
}
body.dark-theme .ant-table-thead th.ant-table-cell {
  color: #d1a3ff !important;
}
body.dark-theme .ant-table-tbody > tr.ant-table-row-selected > td {
  background-color: slategray;
}
.leave-policies-page {
  max-width: 960px;
  margin-top: 32px;
}
.leave-policies-page .ant-divider::before,
.leave-policies-page .ant-divider::after {
  border-top: none;
}
.leave-policies-page #override-notice {
  margin-left: 20%;
  margin-top: 30px;
}
@media (max-width: 768px) {
  #LeavePolicyForm .ant-col-14,
  #LeavePolicyForm .ant-col-10 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.location-body .user-avatar {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 14px;
}
.location-body .ant-tabs-bar .ant-tabs-extra-content {
  margin-right: 15px;
}
.location-body .holidays .ant-tabs-bar {
  margin: 0;
}
@media (max-width: 768px) {
  #locationForm button[type="submit"] {
    margin-top: 15px;
  }
  input[name="yearStartDay"] {
    margin-left: 10px;
  }
}
@media (max-width: 450px) {
  .location-body .holidays .ant-tabs .ant-tabs-extra-content {
    width: 50%;
    display: flex;
    overflow-x: scroll;
  }
}
#chakra-modal--body-rsi {
  height: 150px;
}
.calendar-header {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  flex: 1;
  margin-bottom: 20px;
}
.calendar-header .leave-types-tags {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  flex: 1;
}
.calendar-header .leave-types-tags p {
  margin-bottom: 5px;
}
.calendar-header .filters {
  margin-left: auto;
}
.calendar-header .teams-filter span {
  display: block;
}
.calendar-header .teams-filter .dropdown-teams {
  width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.calendar-header .locations-filter span {
  display: block;
}
.calendar-header .locations-filter:last-child span {
  display: inline-block;
}
.calendar-body a {
  color: #545454;
}
.calendar-body.user-not-work-day-mon .fc tbody .fc-daygrid-body .fc-day-mon {
  background: repeating-linear-gradient(45deg, #fafafa, #f7f7f7 13px, #f5f5f5 12px, #f2f2f2 17px);
}
.calendar-body.user-not-work-day-tue .fc tbody .fc-daygrid-body .fc-day-tue {
  background: repeating-linear-gradient(45deg, #fafafa, #f7f7f7 13px, #f5f5f5 12px, #f2f2f2 17px);
}
.calendar-body.user-not-work-day-wed .fc tbody .fc-daygrid-body .fc-day-wed {
  background: repeating-linear-gradient(45deg, #fafafa, #f7f7f7 13px, #f5f5f5 12px, #f2f2f2 17px);
}
.calendar-body.user-not-work-day-thu .fc tbody .fc-daygrid-body .fc-day-thu {
  background: repeating-linear-gradient(45deg, #fafafa, #f7f7f7 13px, #f5f5f5 12px, #f2f2f2 17px);
}
.calendar-body.user-not-work-day-fri .fc tbody .fc-daygrid-body .fc-day-fri {
  background: repeating-linear-gradient(45deg, #fafafa, #f7f7f7 13px, #f5f5f5 12px, #f2f2f2 17px);
}
.calendar-body.user-not-work-day-sat .fc tbody .fc-daygrid-body .fc-day-sat {
  background: repeating-linear-gradient(45deg, #fafafa, #f7f7f7 13px, #f5f5f5 12px, #f2f2f2 17px);
}
.calendar-body.user-not-work-day-sun .fc tbody .fc-daygrid-body .fc-day-sun {
  background: repeating-linear-gradient(45deg, #fafafa, #f7f7f7 13px, #f5f5f5 12px, #f2f2f2 17px);
}
.calendar-body.start-week-mon-fri .fc-header-toolbar .fc-toolbar-chunk .fc-button-group button.fc-changeWeekMonFri-button {
  color: #7e01ff;
  background-color: #fff;
  border-color: #7d02ff;
  z-index: 2;
}
.calendar-body.start-week-sun-thu .fc-header-toolbar .fc-toolbar-chunk .fc-button-group button.fc-changeWeekSunThu-button {
  color: #7e01ff;
  background-color: #fff;
  border-color: #7d02ff;
  z-index: 2;
}
.calendar-body.calendar-view-dayGridMonth .fc-header-toolbar .fc-toolbar-chunk .fc-button-group button.fc-changeViewMonth-button {
  color: #7e01ff;
  background-color: #fff;
  border-color: #7d02ff;
  z-index: 2;
}
.calendar-body.calendar-view-listMonth .fc .fc-view-harness .fc-list {
  border: none !important;
}
.calendar-body.calendar-view-listMonth .fc .fc-view-harness .fc-list .fc-scroller table,
.calendar-body.calendar-view-listMonth .fc .fc-view-harness .fc-list .fc-scroller th,
.calendar-body.calendar-view-listMonth .fc .fc-view-harness .fc-list .fc-scroller td {
  border: none !important;
}
.calendar-body.calendar-view-listMonth .fc .fc-view-harness .fc-list .half-day-holiday .fc-list-event-time {
  font-size: 0px;
  text-align: center;
}
.calendar-body.calendar-view-listMonth .fc .fc-view-harness .fc-list .half-day-holiday .fc-list-event-time::before {
  font-size: 13px;
  content: "-";
}
.calendar-body.calendar-view-listMonth .fc-header-toolbar .fc-toolbar-chunk .fc-button-group button.fc-changeViewList-button {
  color: #7e01ff;
  border-color: #7d02ff;
  z-index: 2;
}
.calendar-body.calendar-view-listMonth .fc-changeWeekMonFri-button,
.calendar-body.calendar-view-listMonth .fc-changeWeekSunThu-button {
  display: none;
}
.calendar-body .fc-button-group .fc-changeViewMonth-button {
  margin-left: 15px;
}
.calendar-body .fc .fc-button {
  border-radius: 6px;
}
.calendar-body .fc .fc-button-primary,
.calendar-body .fc .fc-button-primary:disabled {
  color: #545454;
  background-color: #fff;
  border-color: #d9d9d9;
  height: 32px;
  padding: 2px 10px;
}
.calendar-body .fc .fc-button-group .fc-button-primary {
  padding: 2px 8px;
}
.calendar-body .fc .fc-button-group > .fc-button:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.calendar-body .fc .fc-button-group > .fc-button:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.calendar-body .fc .fc-button-primary:hover,
.calendar-body .fc .fc-button-primary:active {
  color: #7e01ff !important;
  background-color: #fff !important;
  border-color: #7d02ff !important;
}
.calendar-body .fc .fc-button-primary:not(:disabled):active:focus,
.calendar-body .fc .fc-button-primary:not(:disabled).fc-button-active:focus,
.calendar-body .fc .fc-button-primary:focus {
  box-shadow: none;
}
.calendar-body .fc .fc-button-active:not(active) {
  background-color: #fff !important;
  color: #7e01ff !important;
}
.calendar-body .fc .fc-toolbar .fc-toolbar-title {
  font-weight: 600;
  font-size: 14px;
}
@media (max-width: 768px) {
  .calendar-body .fc .fc-toolbar .fc-toolbar-title {
    text-align: center;
  }
}
@media (max-width: 320px) {
  .calendar-body .fc .fc-toolbar .fc-toolbar-title {
    font-size: 13px;
    margin-left: 7px;
  }
}
.calendar-body .fc .fc-view-harness .fc-scrollgrid {
  border: none;
}
.calendar-body .fc .fc-view-harness .fc-col-header .fc-head-container {
  border: none;
}
.calendar-body .fc .fc-view-harness .fc-col-header .fc-col-header-cell {
  border: none !important;
  text-align: right;
  font-weight: 400;
  padding-right: 8px;
  padding-bottom: 4px;
}
.calendar-body .fc .fc-view-harness .fc-list-table .fc-day-today .fc-cell-shaded {
  background-color: #f5ebff;
}
.calendar-body .fc .fc-view-harness tbody .fc-more-popover .fc-popover-body {
  max-height: 300px;
  overflow: auto;
}
.calendar-body .fc .fc-view-harness tbody .fc-scrollgrid-section-body .fc-day {
  border: none;
}
.calendar-body .fc .fc-view-harness tbody .fc-scrollgrid-section-body .fc-day-past {
  background-color: #fafafa;
}
.calendar-body .fc .fc-view-harness tbody .fc-scrollgrid-section-body .fc-day-past .fc-daygrid-day-number {
  color: rgba(0, 0, 0, 0.25);
}
.calendar-body .fc .fc-view-harness tbody .fc-scrollgrid-section-body .fc-popover.fc-day-today {
  background-color: #fff;
}
.calendar-body .fc .fc-view-harness tbody .fc-scrollgrid-section-body .fc-day-today {
  background: none;
}
.calendar-body .fc .fc-view-harness tbody .fc-scrollgrid-section-body .fc-day-today .fc-daygrid-day-bg {
  width: calc(100% - 10px);
  height: 100%;
  display: block;
  background-color: #f5ebff;
  top: 3px;
  position: absolute;
  left: 5px;
}
.calendar-body .fc .fc-view-harness tbody .fc-scrollgrid-section-body .fc-day-today .fc-daygrid-day-number {
  border-color: #7e01ff;
  color: #7f00ff;
}
.calendar-body .fc .fc-view-harness tbody .fc-scrollgrid-section-body .fc-daygrid-day-number {
  display: inline-block;
  text-align: right;
  width: calc(100% - 10px);
  border-top: 2px solid;
  border-color: #dcdcdc;
  padding-top: 2px;
  padding-right: 5px;
  position: relative;
  right: 5px;
}
.calendar-body .fc .fc-view-harness tbody .fc-scrollgrid-section-body .fc-daygrid-more-link {
  left: 6px;
  z-index: 8;
}
.calendar-body .fc .fc-view-harness tbody .fc-day-other .fc-daygrid-day-top {
  opacity: 1;
}
.calendar-body .fc .fc-view-harness tbody .fc-event {
  padding-left: 5px;
}
.calendar-body .fc .fc-view-harness tbody .fc-event.striped {
  background: repeating-linear-gradient(35deg, #bbbabab0, #bbbabab0 1px, transparent 1px, transparent 5px);
}
.calendar-body .fc-popover .fc-popover-body .striped .fc-event-main {
  background: repeating-linear-gradient(35deg, #bbbabab0, #bbbabab0 1px, transparent 1px, transparent 5px);
}
.calendar-body .fc-popover .fc-popover-body .fc-daygrid-event-harness .fc-event .fc-event-title {
  padding-left: 3px;
}
.paragraph-with-top-divider {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid;
  border-color: #d9d9d9;
}
@media (max-width: 768px) {
  .calendar-body .fc-media-screen .fc-header-toolbar .fc-toolbar-chunk:last-child {
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
  }
  .calendar-body .fc-media-screen .fc-header-toolbar .fc-toolbar-chunk:last-child .fc-button-group:first-child {
    margin-bottom: 0px;
  }
  .calendar-body .fc-media-screen .fc-header-toolbar .fc-toolbar-chunk:last-child .fc-button-group button {
    height: auto;
  }
  .calendar-body .fc-media-screen .fc-header-toolbar .fc-toolbar-chunk .fc-today-button {
    margin-left: 5px;
    margin-top: 0px;
  }
}
@media (max-width: 768px) and (max-width: 425px) {
  .calendar-body .fc-media-screen .fc-header-toolbar .fc-toolbar-chunk:last-child .fc-button-group:first-child {
    margin-bottom: 10px;
  }
}
@media (max-width: 768px) and (max-width: 425px) {
  .calendar-body .fc-media-screen .fc-header-toolbar .fc-toolbar-chunk .fc-today-button {
    margin-left: 0px;
    margin-top: 10px;
  }
}
@media (max-width: 425px) {
  .vt-callendar-sync-button button {
    margin-left: 0px !important;
  }
}
@media (max-width: 321px) {
  .vt-callendar-sync-button button {
    font-size: 12px;
    padding: 0px 10px;
  }
}
.teams-body .btn-actions {
  margin-bottom: 10px;
}
.teams-body table .users a {
  display: inline-block;
  margin-right: 5px;
}
.teams-body .user-avatar {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: -4px;
  margin-bottom: 14px;
  width: 32px;
}
.teams-body .user-avatar > div {
  width: 32px;
  display: inline-block;
}
.teams-body .more-users {
  padding: 0 6px;
  line-height: 32px;
}
.profile-banner {
  padding: 32px;
  padding-bottom: 98px;
  margin: -32px;
  margin-bottom: -72px;
  background-color: #7f00ffe0;
  color: #ffffff;
  position: relative;
}
.profile-banner-top {
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  margin-bottom: 20px;
}
.profile-banner-top-left {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.profile-banner-top-right {
  text-align: right;
  margin-left: auto;
}
.profile-banner-avatar {
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 1.5rem;
}
.profile-banner-avatar-overlay {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  width: 100%;
  bottom: 0;
  text-align: center;
  height: 30%;
  line-height: 24px;
  cursor: pointer;
  color: #ffffff;
}
.profile-banner-avatar-info h2 {
  color: #ffffff;
}
.profile-banner-bottom {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.profile-banner-bottom .ant-menu.ant-menu-horizontal {
  line-height: 30px;
  border: none;
  background-color: transparent;
  min-width: 550px;
  margin-bottom: 15px;
}
.profile-banner-bottom .ant-menu.ant-menu-horizontal .ant-menu-item {
  color: #ffffff;
}
.profile-banner-bottom .ant-menu.ant-menu-horizontal .ant-menu-item.btn {
  border: 1px solid #ffffff;
  border-radius: 5px;
}
.profile-banner-bottom .ant-menu.ant-menu-horizontal .ant-menu-item.btn::after {
  display: none;
}
.profile-banner-bottom .ant-menu.ant-menu-horizontal .ant-menu-item.btn:hover {
  color: #a2a2a2;
  border-color: #a2a2a2;
}
.profile-banner-bottom .ant-menu.ant-menu-horizontal .ant-menu-item:hover,
.profile-banner-bottom .ant-menu.ant-menu-horizontal .ant-menu-item-selected {
  color: #ffffff;
}
.profile-banner-bottom .ant-menu.ant-menu-horizontal .ant-menu-item:hover::after,
.profile-banner-bottom .ant-menu.ant-menu-horizontal .ant-menu-item-selected::after {
  border-color: #ffffff;
}
.profile-banner-bottom .ant-breadcrumb {
  margin-left: auto;
}
.profile-banner-bottom .ant-breadcrumb-link a {
  color: #ffffff;
}
.profile-banner-bottom .ant-breadcrumb li:last-child {
  color: #ffffff;
}
.profile-container {
  position: relative;
  z-index: 2;
}
.user-page {
  position: relative;
  z-index: 2;
}
.user-page .ant-descriptions-item-content {
  font-weight: 600;
}
.user-page .user-offtoday {
  margin-bottom: 10px;
}
.user-page .user-offtoday-reason {
  margin-bottom: 0;
}
body.dark-theme .profile-banner {
  background-color: #22282e;
}
body.dark-theme .profile-banner-bottom .ant-menu {
  background-color: #22282e;
}
.not-found-content {
  height: 100%;
  background: url('../assets/images/empty.png');
  background-repeat: no-repeat;
  background-position: right 10%;
  background-size: 45%;
}
.not-found-content .title {
  padding-top: 10%;
  font-size: 34px;
}
.app-layout.create-company .content-layout .ant-layout-content .main-content-wrapper {
  padding: 22px;
}
.app-layout.create-company .content-layout .ant-layout-content .main-content-wrapper .main-content {
  margin-bottom: 0;
}
.app-layout.create-company .content-layout .ant-layout-content .main-content-wrapper .main-content .footer-import-users {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.app-layout.create-company .select-plan .plans {
  position: relative;
}
.app-layout.create-company .select-plan .plans .loader-wrap {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 4;
}
.app-layout.create-company .select-plan .plans .loader-wrap .loader {
  position: absolute;
  left: 50%;
  margin-left: -30px;
  height: 60px;
  top: 50%;
  margin-top: -30px;
}
.app-layout.create-company .pto-info {
  text-align: center;
}
.app-layout.create-company .agree-terms {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
@media screen and (min-width: 840px) {
  .app-layout.create-company .price-plan-card .card-body {
    padding: 40px 40px 30px;
  }
}
@media screen and (min-width: 768px) {
  .app-layout.create-company .price-plan-card .card-body {
    padding: 40px 28px 30px;
  }
}
.microsoft-saas-create-company .main-content-body {
  height: 100%;
}
.microsoft-saas-create-company .card-box {
  margin-top: 20px;
  max-width: 600px;
}
.microsoft-saas-create-company .card-box.completed {
  border-color: #535353;
  background-color: #f5f5f5;
}
.microsoft-saas-create-company .card-box.completed.error {
  border-color: #ff4d4f;
}
.microsoft-saas-create-company .card-box.disabled {
  opacity: 0.6;
}
.microsoft-saas-create-company .card-box .ant-card-body {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  padding: 15px 20px;
}
.microsoft-saas-create-company .card-box .ant-card-body .link {
  flex: 1 auto;
}
.microsoft-saas-create-company .card-box .ant-card-body .link span {
  color: #a2a2a2;
}
.microsoft-saas-create-company .card-box .ant-card-body .link-icon {
  font-size: 16px;
  position: relative;
  top: 1px;
  margin-left: 10px;
  color: #7f00ff;
}
.microsoft-saas-create-company .card-box .ant-card-body .link-icon.error {
  color: #ff4d4f;
}
.microsoft-saas-create-company .card-box .ant-card-body .link-right {
  color: #7f00ff;
}
.microsoft-saas-create-company .help-info {
  margin-top: 20px;
}
.microsoft-saas-create-company .footer {
  margin-top: 20px;
  text-align: right;
}
.microsoft-saas-create-company .assign-licenses > .footer {
  text-align: left;
  margin-top: 80px;
}
.microsoft-saas-create-company .ant-alert .ant-alert-content .ant-alert-description {
  white-space: pre-line;
}
.leave-types-body {
  margin: 20px 0;
}
.leave-types-body .color span {
  padding: 4px 10px;
  font-size: 12px;
  border-radius: 20px;
}
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}
.row-dragging td {
  padding: 16px;
  visibility: hidden;
}
.row-dragging .drag-visible {
  visibility: visible;
}
.dark-theme button.ant-switch-checked {
  background-color: #7f00ff;
}
.billing-body .ant-alert .ant-alert-content .ant-alert-description {
  white-space: pre-line;
}
#wallchart .clickable {
  cursor: pointer;
}
#wallchart .ant-table .ant-table-row .ant-table-cell.ant-table-cell-row-hover {
  background-color: #f2e6ff;
}
#wallchart .ant-table {
  width: 100%;
  font-size: 12px;
}
#wallchart .ant-table td,
#wallchart .ant-table th {
  padding: 0px;
  height: 40px;
  text-align: center;
}
#wallchart .ant-table td {
  padding: 0;
}
#wallchart .ant-table-thead > tr:first-child {
  position: sticky !important;
  top: 0;
  z-index: 1;
  background-color: #fff;
}
#wallchart .ant-table-cell {
  width: 40px;
  height: 40px;
  padding: 0px;
}
#wallchart .ant-table-cell.today,
#wallchart .ant-table-body .ant-table-cell.today {
  border-width: 0 2px;
  border-style: solid;
  border-color: #999;
}
#wallchart .ant-table-body tr:last-child .ant-table-cell.today {
  border-width: 0 2px 2px 2px;
  border-style: solid;
  border-color: #999;
}
#wallchart .ant-table-header {
  position: sticky;
  top: 0px;
  z-index: 2;
}
#wallchart .ant-table-header .today {
  border: 2px solid #999;
}
#wallchart .profile-column {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
#wallchart .profile-column div {
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
}
#wallchart .profile-column div:nth-child(1) {
  flex-basis: 50px;
}
#wallchart .profile-column div.profile-info {
  flex-basis: calc(100% - 50px);
  font-size: 14px;
  line-height: 16px;
  text-align: left;
}
#wallchart .profile-column div.profile-info > div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#wallchart .ant-table-body .ant-table-cell {
  padding: 4px 0;
  border: none;
}
#wallchart .leave {
  height: 40px;
  width: 100%;
  vertical-align: middle;
  margin: 0;
  line-height: 40px;
  padding: 0;
  cursor: pointer;
}
#wallchart .leave-pending::before {
  content: "?";
  position: absolute;
  top: -7px;
  right: 16px;
  font-size: 14px;
  font-weight: bold;
}
#wallchart .leave-multi::before {
  content: "*";
  position: absolute;
  top: -7px;
  right: 17px;
  font-size: 14px;
  font-weight: bold;
}
#wallchart .leave-pending.leave-multi::before {
  content: "?*";
  position: absolute;
  top: -7px;
  right: 12px;
  font-size: 14px;
  font-weight: bold;
}
#wallchart .table-legend {
  margin-top: 30px;
}
#wallchart .table-legend span.ant-tag {
  margin-right: 7px;
  margin-left: 0px;
  margin-bottom: 10px;
  cursor: pointer;
}
#wallchart .wallchart-rangepicker-wrapper {
  display: flex;
  align-items: center;
}
#wallchart .wallchart-rangepicker-wrapper .wallchart-date-navigation span {
  margin: 0 5px;
  width: 10px;
  cursor: pointer;
}
#wallchart .wallchart-rangepicker-wrapper .wallchart-pending-radio {
  margin: 0 30px;
}
.dark-theme #wallchart .ant-table .ant-table-row .ant-table-cell.ant-table-cell-row-hover {
  background-color: #677c8d;
}
.dark-theme #wallchart button.ant-switch-checked {
  background-color: #7f00ff;
}
@media (max-width: 1050px) {
  #wallchart .calendar-header .wallchart-rangepicker-wrapper {
    flex-wrap: wrap;
  }
  #wallchart .calendar-header .wallchart-rangepicker-wrapper .ant-picker-range {
    width: 30%;
  }
  #wallchart .calendar-header .wallchart-rangepicker-wrapper .wallchart-date-navigation {
    width: 10%;
  }
  #wallchart .calendar-header .wallchart-rangepicker-wrapper .wallchart-pending-radio {
    margin: 10px 0px 5px 0px;
  }
  #wallchart .calendar-header .wallchart-rangepicker-wrapper .filters {
    width: 100%;
    margin-top: 10px;
  }
}
@media (max-width: 768px) {
  #wallchart .calendar-header .wallchart-rangepicker-wrapper .ant-picker-range {
    width: 80%;
  }
  #wallchart .calendar-header .wallchart-rangepicker-wrapper .wallchart-date-navigation {
    width: 20%;
  }
  #wallchart .calendar-header .wallchart-rangepicker-wrapper .wallchart-pending-radio {
    margin: 10px 0px 5px 0px;
  }
  #wallchart .calendar-header .wallchart-rangepicker-wrapper .filters {
    margin-top: 0px;
  }
  #wallchart .table-legend {
    display: flex;
    flex-wrap: wrap;
  }
  #wallchart .table-legend span.ant-tag {
    width: 48%;
  }
}
@media (max-width: 321px) {
  #wallchart .table-legend span.ant-tag {
    width: 100%;
  }
}
.main-content-info {
  display: flex;
  justify-content: space-between;
}
.main-content-info button {
  margin-left: 10px;
}
.feedback-icon {
  color: #7f00ff;
}
.ai-assistant-chat {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.ai-assistant-chat-header {
  position: sticky;
  display: flex;
  z-index: 5;
  justify-content: space-between;
  align-items: center;
  top: 0;
  background-color: white;
  padding: 10px;
  font-size: 18px;
  background-color: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(13px) brightness(0.84) contrast(168%);
}
.ai-assistant-chat-header .button {
  margin-right: auto;
  /* Keeps the button on the far left */
}
.ai-assistant-chat-header .center-content {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}
.ai-assistant-chat-conversation {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  margin: 0 auto;
  max-width: 800px;
}
.ai-assistant-chat-conversation-message-wrapper {
  display: block;
  overflow: hidden;
  width: 100%;
}
.ai-assistant-chat-conversation-message {
  display: block;
  max-width: 85%;
  width: fit-content;
  background-color: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  margin: 10px;
  padding: 10px;
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1);
  white-space: pre-wrap;
  font-size: 16px;
  line-height: 1.6;
  color: #000;
}
.ai-assistant-chat-conversation-message-assistant {
  float: left;
  background-color: #f5f5f5;
}
.ai-assistant-chat-conversation-message-user {
  float: right;
}
.ai-assistant-chat-conversation-message-avatar {
  margin-right: 12px;
}
.ai-assistant-chat-conversation-message-content {
  margin: 10px 20px 0;
  font-family: Georgia, serif;
}
.ai-assistant-chat-conversation-message-username {
  flex-grow: 1;
  margin-right: 10px;
  color: #7f00ff;
}
.ai-assistant-chat-conversation-message-timestamp {
  font-size: 12px;
  text-align: right;
}
.ai-assistant-chat-conversation-message-user .ai-assistant-chat-conversation-message-content {
  margin: 10px 20px 0 44px;
}
.ai-assistant-chat-footer {
  position: sticky;
  bottom: 0;
  background-color: white;
  display: flex;
  align-items: center;
  max-width: 1200px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.ai-assistant-conversation-list-container {
  max-width: 1200px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.ai-assistant-conversation-list-input {
  margin-bottom: 10px;
}
.ant-input-group .ant-input.ai-assistant-conversation-list-input-field,
.ai-assistant-conversation-list-input-field {
  width: calc(100% - 100px);
}
.ai-assistant-conversation-list-input-button {
  width: 100px;
}
.ai-assistant-conversation-list-timestamp {
  font-size: 14px;
  color: #a2a2a2;
}
.ai-assistant-input-disclaimer {
  text-align: center;
  margin: 0 auto;
  font-size: 12px;
  width: 85%;
}
.location-from .ant-checkbox-group-item {
  width: 115px;
}
.loader {
  position: relative;
  margin: 0 auto;
  width: 60px;
  height: 100vh;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
}
.leaves-box {
  display: flex;
}
@media (max-width: 768px) {
  .leaves-box {
    overflow-x: scroll;
    padding-bottom: 10px;
  }
}
.leaves-box .avatar {
  margin-right: 12px;
}
.leaves-box .content {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.leaves-box .content .info {
  display: flex;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  margin-bottom: 5px;
}
.leaves-box .content .info .user h4 {
  display: inline-block;
  margin-bottom: 0;
  margin-top: 0;
}
.leaves-box .content .leaves-info {
  display: flex;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
}
.leaves-box .content .leaves-info p {
  margin-bottom: 0;
}
.insert-holidays-form .ant-steps {
  margin-bottom: 20px;
}
.insert-holidays-form .ant-checkbox-group-item {
  display: block;
  margin-bottom: 4px;
}
.heatmap {
  margin-bottom: 20px;
}
.heatmap .heatmap-navigation {
  display: flex;
  align-items: center;
  line-height: 32px;
  height: 52px;
  border-bottom: 1px solid #EDEDED;
  margin-bottom: 10px;
  padding: 10px 0;
  justify-content: space-between;
}
.heatmap .heatmap-selected-date {
  display: block;
  margin: 10px auto;
  padding: 20px;
  border-top: 1px solid #EDEDED;
  border-bottom: 1px solid #EDEDED;
}
@media (max-width: 768px) {
  .heatmap .heatmap-selected-date {
    padding: 5px;
  }
}
@media (max-width: 768px) {
  .vt-current-month strong {
    line-height: 20px;
    display: block;
    text-align: center;
  }
}
@media (max-width: 321px) {
  .heatmap-navigation .ant-btn {
    padding: 4px 0px;
    font-size: 13px;
  }
  .vt-current-month strong {
    font-size: 13px;
    line-height: 16px;
  }
}
.stripe-input .ant-form-item-control-input {
  padding: 6.5px 11px;
  font-size: 16px;
  color: #545454;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  line-height: 1.5715;
  height: 40px;
}
.stripe-input .ant-form-item-control-input:hover {
  border: 1px solid #7f00ff;
}
.StripeElement--webkit-autofill {
  transition: background-color 5000s ease-in-out 0s;
  background-color: transparent !important;
}
.ant-collapse.hidden-leave-types .ant-collapse-item .ant-collapse-header {
  text-align: center;
  color: #7f00ff;
}
.ant-collapse.hidden-leave-types .ant-collapse-item .ant-collapse-header .ant-collapse-arrow {
  position: relative;
  left: -10px;
  top: 5px;
}
.ant-collapse.hidden-leave-types .ant-collapse-item .ant-collapse-content-box {
  padding: 0;
}
.search .ant-input-suffix .ant-spin-dot {
  top: 3px;
}
.search .ant-input-suffix .ant-select-selection-placeholder {
  top: 1px;
}
.dynamic-field {
  margin-bottom: 5px;
}
.user-logs-card .ant-card-head .ant-card-head-wrapper .ant-card-head-title {
  font-weight: normal;
  font-size: 14px;
  padding: 16px 0;
}
.edit-timezone {
  color: #7f00ff;
}
@media (max-width: 768px) {
  .user-logs-card {
    margin-bottom: 0px !important;
  }
  .user-logs-card .ant-card-head .ant-card-head-wrapper .ant-row .ant-col {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .user-logs-card .ant-card-head .ant-card-head-wrapper .ant-row .ant-col .ant-picker-range {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}
@media (max-width: 376px) {
  .user-logs-card .ant-card-head .ant-card-head-wrapper .ant-row .ant-col {
    width: 100%;
  }
  .user-logs-card .ant-card-head .ant-card-head-wrapper .ant-row .ant-col .ant-select {
    width: 100% !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}
.label-color {
  width: 15px;
  height: 15px;
  display: block;
  border-radius: 50%;
}
.user-labels-tag-bg {
  background: #fff;
  height: 28px;
  display: inline-block;
  border-radius: 6px;
  margin-right: 8px;
  margin-bottom: 8px;
}
.user-labels-tag {
  margin-right: 0;
  height: 28px;
  line-height: 27px;
  font-size: 12px;
}
.user-labels-plus {
  height: 27px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 12px;
  border: 1px dashed #a2a2a2;
  line-height: 27px;
  top: 0px;
  position: relative;
}
.user-labels-select {
  width: 200px;
}
.delete-label {
  max-width: 335px;
}
.manage-labels #color {
  width: 50px;
  padding: 3px 6px;
}
.disabled-row {
  pointer-events: none;
  opacity: 0.4;
}
.dark-theme .ant-table-tbody > tr.editable-row.ant-table-row:hover > td,
.dark-theme .ant-table-tbody > tr.editable-row > td.ant-table-cell-row-hover {
  background-color: transparent;
}
@media (max-width: 768px) {
  .user-profile-page .ant-card-head {
    padding: 0 5px;
  }
  .user-profile-page .ant-card-body > div {
    padding: 5px 5px !important;
  }
  .user-profile-page .ant-card-body > div .ant-row .ant-col-8 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .user-profile-page .user-leave-quotas button {
    min-width: 100% !important;
    margin-left: 0px !important;
  }
  #userUpdateForm input {
    width: 100% !important;
  }
}
@media (max-width: 325px) {
  .profile-banner-bottom {
    overflow-x: scroll;
  }
}
.price-plan-card {
  -webkit-box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: scale(0.95);
  -moz-transform: scale(0.95);
  -ms-transform: scale(0.95);
  -o-transform: scale(0.95);
  transform: scale(0.95);
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-align-items: stretch;
  -ms-align-items: stretch;
  align-items: stretch;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  border: 1px solid #d9d9d9;
  text-align: center;
  background-color: #ffffff;
}
.price-plan-card.current-plan {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  border-color: #7f00ff;
}
.price-plan-card.current-plan .card-header {
  background-color: #7f00ff;
}
.price-plan-card .card-header {
  position: relative;
  color: #ffffff;
  padding: 30px 0px;
  background-color: #5200a5;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.price-plan-card .card-header .plan-name-wrap {
  font-size: 22px;
  line-height: 22px;
  padding-bottom: 15px;
  font-weight: 300;
}
.price-plan-card .card-header .price {
  position: relative;
}
.price-plan-card .card-header .price .old-price {
  opacity: 0.6;
}
.price-plan-card .card-header .price .old-price .strikethrough {
  position: relative;
  padding: 0 0.15em;
}
.price-plan-card .card-header .price .old-price .strikethrough:before {
  -webkit-transform: rotate(-25deg);
  -moz-transform: rotate(-25deg);
  -ms-transform: rotate(-25deg);
  -o-transform: rotate(-25deg);
  transform: rotate(-25deg);
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  border-top: 2px solid;
  border-color: inherit;
}
.price-plan-card .card-header .plan-price-header {
  font-size: 22px;
  line-height: 29px;
  margin-bottom: 2px;
  font-weight: 600;
  min-height: 32px;
}
.price-plan-card .card-header .plan-price-header sup {
  font-size: 15px;
}
.price-plan-card .card-header .plan-price-header .price {
  margin-right: 6px;
  font-size: 28px;
}
.price-plan-card .card-header .plan-price-header .price .old-price {
  margin-right: 36px;
  font-size: 24px;
}
.price-plan-card .card-header .plan-price-header .price .old-price .strikethrough {
  padding: 0 0.15em;
}
.price-plan-card .card-header .plan-price-header .price .old-price .strikethrough:before {
  -webkit-transform: rotate(-40deg);
  -moz-transform: rotate(-40deg);
  -ms-transform: rotate(-40deg);
  -o-transform: rotate(-40deg);
  transform: rotate(-40deg);
  border-top: 1px solid;
}
.price-plan-card .card-header .plan-price-header .price .new-price {
  -webkit-transform: rotate(-8deg);
  -moz-transform: rotate(-8deg);
  -ms-transform: rotate(-8deg);
  -o-transform: rotate(-8deg);
  transform: rotate(-8deg);
  position: absolute;
  right: 2px;
  font-size: 31px;
}
.price-plan-card .card-header .plan-price-total-minimum {
  margin-bottom: 0px;
}
.price-plan-card .card-header .plan-price-total-minimum .old-price {
  margin-right: 2px;
}
.price-plan-card .card-header .price-contact-us {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  margin: 12px 0px;
}
.price-plan-card .card-body {
  padding: 40px 40px 30px 40px;
  text-align: left;
  color: #262626;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  flex: 1;
}
.price-plan-card .card-body p {
  position: relative;
  padding-left: 25px;
  margin-bottom: 20px;
}
.price-plan-card .card-body p .anticon {
  margin-right: 10px;
  position: absolute;
  left: 0;
  top: 3px;
}
.price-plan-card .card-footer {
  padding: 0 10px 20px 10px;
}
.price-plan-card .card-footer .btn-disabled {
  color: #545454;
  border-color: #545454;
  background: #a2a2a2;
}
.price-plan-card .card-footer .change-price-info {
  margin-top: 2px;
  height: 36px;
}
.price-plan-card .no-margin {
  margin: 0 !important;
}
.switch-plan-wrapper {
  background-color: #f0f2f5;
  border: 1px solid #d9d9d9;
  padding: 12px 24px;
  border-radius: 6px;
}
.switch-plan-wrapper .wrapper-plan-vt {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.switch-plan-wrapper .wrapper-plan-vt .ant-typography {
  margin-bottom: 0px;
  margin-top: 0px;
}
.plan-period-switch {
  display: flex;
  gap: 0px 8px;
  width: 200px;
  margin: 0 auto;
  justify-content: center;
}
body:not(.dark-theme) .more-details {
  border: 1px solid #d9d9d9;
  background: white;
}
.notification-destinations .ant-form-item {
  margin-bottom: 0;
}
.notification-destinations .ant-input-group.ant-input-group-compact {
  margin-bottom: 5px;
}
@media (max-width: 768px) {
  .notification-destinations .ant-input-group.ant-input-group-compact {
    display: flex;
  }
  .notification-destinations .ant-input-group.ant-input-group-compact .ant-row {
    width: 100%;
  }
  .notification-destinations .ant-input-group.ant-input-group-compact .anticon-minus-circle {
    margin-left: 10px !important;
  }
}
.notification-destinations .ant-select .ant-select-selector {
  border-radius: 0;
}
.notification-destinations .ant-select.select-emails .ant-select-selector,
.notification-destinations .ant-select.select-channel-from-slack .ant-select-selector {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.notification-destinations .fake-input {
  max-width: 250px;
  margin-left: 5px;
  border-radius: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
@media (max-width: 768px) {
  .vt-content-notifications .main-content-header {
    margin-bottom: 10px !important;
  }
  .vt-content-notifications .main-content-body .empty-data {
    padding: 0px;
  }
  .vt-content-notifications .main-content-body .empty-data .ant-col a:last-child {
    margin-left: 0px  !important;
  }
  .vt-content-notifications .main-content-body .empty-data .ant-col a:last-child button {
    margin-top: 10px;
  }
  .vt-content-notifications .main-content-body .vt-filters-wrapper {
    margin-top: 10px;
  }
  .vt-notifications-form-page input {
    max-width: 100% !important;
  }
  .vt-notifications-form-page #notificationsForm_sendOnDays,
  .vt-notifications-form-page .ant-radio-group,
  .vt-notifications-form-page .vt-notification-radio-btn {
    display: flex;
    flex-wrap: wrap;
  }
  .vt-notifications-form-page #notificationsForm_sendOnDays label,
  .vt-notifications-form-page .ant-radio-group label,
  .vt-notifications-form-page .vt-notification-radio-btn label {
    margin-left: 0px;
    width: 45%;
  }
  .vt-notifications-form-page .vt-notification-message .vt-primary-color {
    text-align: left !important;
  }
  .vt-notifications-form-page .vt-btn-reset {
    margin-right: 0px !important;
  }
  .vt-notifications-form-page .vt-notification-radio-btn label {
    width: 100%;
  }
  .vt-notifications-form-page .vt-notification-radio-btn label:first-child {
    margin-bottom: 5px;
  }
}
@media (max-width: 321px) {
  .vt-notifications-form-page .vt-save-notification {
    width: 50%;
    margin-top: 0px;
  }
}
.manage-assign-licenses .header {
  margin: 20px 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
}
.manage-assign-licenses .header .search {
  height: 32px;
}
.manage-assign-licenses .errors {
  margin: 0 0 20px;
}
.manage-assign-licenses .user-card.user-card-header .ant-card-body {
  border-top: 2px solid #5a5a5a;
  margin-bottom: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.manage-assign-licenses .user-card.user-card-header .ant-card-body span,
.manage-assign-licenses .user-card.user-card-header .ant-card-body div {
  align-items: center;
  display: flex;
}
.manage-assign-licenses .user-card.user-card-header .ant-card-body .check-box {
  font-size: 16px;
  padding-top: 1px;
  cursor: pointer;
  width: 28px;
}
.manage-assign-licenses .user-card.user-card-header .ant-card-body .select-announce {
  display: block;
  line-height: 20px;
}
.manage-assign-licenses .user-card.user-card-header .ant-card-body .select-announce .ant-select-selector {
  height: 28px;
}
.manage-assign-licenses .user-card .ant-card-body {
  padding: 0 0 8px 0;
  border-bottom: 1px solid #eee;
  line-height: 32px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
}
.manage-assign-licenses .user-card .ant-card-body:before {
  display: none;
}
.manage-assign-licenses .user-card .ant-card-body .ant-checkbox-wrapper {
  padding-right: 10px;
  padding-top: 4px;
}
.manage-assign-licenses .user-card .ant-card-body .name {
  width: calc(70% - 376px);
}
.manage-assign-licenses .user-card .ant-card-body .email {
  width: calc(30% - 8px);
  margin-right: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.manage-assign-licenses .user-card .ant-card-body .select-role {
  margin-right: 10px;
  width: 130px;
}
.manage-assign-licenses .user-card .ant-card-body .select-role .ant-select {
  width: 100%;
}
.manage-assign-licenses .user-card .ant-card-body .select-announce {
  width: 210px;
}
.manage-assign-licenses .user-card .ant-card-body .select-announce .ant-select {
  width: 100%;
}
.manage-assign-licenses .infinite-scroll-wrap {
  min-height: 300px;
  overflow-x: hidden;
  height: calc(100vh - 365px);
}
.manage-assign-licenses .bottom-border-box {
  box-shadow: 0 -4px 8px -6px #000000;
  height: 15px;
  width: calc(100% + 6px);
  position: absolute;
  z-index: 2;
  left: -3px;
  bottom: -18px;
}
.manage-assign-licenses .footer {
  margin-top: 20px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .manage-assign-licenses .header div > span {
    margin-left: 0px !important;
    display: block;
  }
  .manage-assign-licenses .user-card .ant-card-body {
    flex-wrap: wrap;
  }
  .manage-assign-licenses .user-card .ant-card-body .name {
    width: calc(50% - 26px);
    line-height: 28px;
  }
  .manage-assign-licenses .user-card .ant-card-body .email {
    width: 50%;
    margin-right: 0;
  }
  .manage-assign-licenses .user-card .ant-card-body .select-role {
    width: calc(50% - 10px);
  }
  .manage-assign-licenses .user-card .ant-card-body .select-announce {
    width: 50%;
  }
  .manage-assign-licenses .user-card.user-card-header .ant-card-body .name {
    width: calc(50% - 28px);
  }
}
@media (max-width: 600px) {
  .manage-assign-licenses .header .search {
    max-width: 50%;
  }
  .manage-assign-licenses .user-card .ant-card-body {
    flex-wrap: wrap;
  }
  .manage-assign-licenses .user-card .ant-card-body .name {
    width: calc(100% - 26px);
    line-height: 28px;
  }
  .manage-assign-licenses .user-card .ant-card-body .email {
    width: 100%;
    margin-right: 0;
  }
  .manage-assign-licenses .user-card .ant-card-body .select-role {
    margin-right: 0;
    width: 100%;
  }
  .manage-assign-licenses .user-card .ant-card-body .select-announce {
    width: 100%;
  }
  .manage-assign-licenses .user-card.user-card-header .ant-card-body .name {
    width: calc(100% - 28px);
  }
}
.ant-drawer-right.onboarding-admin .ant-drawer-content-wrapper {
  border-left: 1px solid #d9d9d9;
  box-shadow: none;
}
.ant-drawer-right.onboarding-admin .ant-drawer-header {
  border-bottom: none;
}
.ant-drawer-right.onboarding-admin .ant-drawer-body {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.ant-drawer-right.onboarding-admin .ant-drawer-body .ant-progress-inner {
  border-radius: 0;
}
.ant-drawer-right.onboarding-admin .ant-drawer-body .ant-progress-inner .ant-progress-bg {
  border-radius: 0;
}
.ant-drawer-right.onboarding-admin .ant-drawer-body .ant-collapse {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}
.ant-drawer-right.onboarding-admin .ant-drawer-body .ant-collapse .ant-collapse-header {
  font-size: 16px;
}
.ant-drawer-right.onboarding-admin .ant-drawer-body .ant-collapse > .ant-collapse-item:last-child {
  border-radius: 0;
}
.ant-drawer-right.onboarding-admin .ant-drawer-body .ant-collapse .anticon-check-circle {
  font-size: 18px;
  top: 3px;
  position: relative;
  margin-right: 5px;
}
.ant-drawer-right.onboarding-admin .ant-drawer-body .youtube-link {
  position: relative;
  cursor: pointer;
  margin-bottom: 15px;
}
.ant-drawer-right.onboarding-admin .ant-drawer-body .youtube-link .youtube-link-black,
.ant-drawer-right.onboarding-admin .ant-drawer-body .youtube-link .youtube-link-red {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: absolute;
  top: calc(50% - 18px);
  height: 36px;
  left: calc(50% - 26px);
  opacity: 0.8;
}
.ant-drawer-right.onboarding-admin .ant-drawer-body .youtube-link .youtube-link-red {
  opacity: 0;
}
.ant-drawer-right.onboarding-admin .ant-drawer-body .youtube-link:hover .youtube-link-red {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  opacity: 0.8;
}
.youtube-modal .ant-modal-body {
  padding: 24px 26px 0;
}
.youtube-modal .ant-modal-close {
  top: -11px;
  position: absolute;
  color: #7f00ff;
  right: -13px;
}
.youtube-modal .ant-modal-close .anticon {
  font-size: 20px;
}
.whats-new .help-drawer-card.unread .ant-card-body {
  padding-top: 44px;
}
.whats-new .help-drawer-card .timestamp {
  float: right;
  font-size: 12px;
  top: 9px;
  position: relative;
}
.flow-b-theme .ant-modal-content {
  background-color: #fff !important;
  color: #000 !important;
}
.flow-b-theme .ant-modal-content .ant-modal-header {
  background-color: #7414f5 !important;
}
.flow-b-theme .ant-modal-content .ant-modal-body .ant-typography {
  color: #000 !important;
}
.flow-b-theme .ant-modal-content .ant-modal-close-x {
  background-color: #7414f5 !important;
  height: 55px;
}
.flow-b-theme .ant-modal-content .ant-modal-title {
  color: #fff;
  line-height: 23px;
}
.flow-b-theme .ant-modal-content button {
  background-color: #fff !important;
  color: #000 !important;
}
.flow-b-theme .ant-modal-content button:hover {
  background-color: #7414f5 !important;
}
body.flow-b-theme .ant-notification-notice .ant-notification-notice-with-icon .ant-notification-notice-description .ant-typography {
  color: #000 !important;
}
.flow-b-theme div div.ant-notification-notice {
  background-color: white !important;
  color: black !important;
}
.flow-b-theme div div.ant-notification-notice .ant-notification-notice-content .ant-notification-notice-description,
.flow-b-theme div div.ant-notification-notice .ant-notification-notice-content .ant-notification-notice-message {
  background-color: white !important;
  color: black !important;
}
.flow-b-theme div div.ant-notification-notice .ant-notification-notice-content .ant-notification-notice-description strong,
.flow-b-theme div div.ant-notification-notice .ant-notification-notice-content .ant-notification-notice-message strong {
  color: black !important;
}
.flow-b-theme .auth-wrap.flow-b {
  background-color: #7414f5;
}
@media (max-width: 600px) {
  .flow-b-theme .auth-wrap.flow-b {
    background-color: white;
  }
}
.flow-b-theme .ant-select-item-option-content {
  color: #000;
}
.flow-b-theme #flow-b {
  color: #000000;
}
.flow-b-theme #flow-b a {
  color: #7414f5 !important;
}
.flow-b-theme #flow-b a:hover {
  color: #281853 !important;
}
.flow-b-theme #flow-b button {
  background-color: #7414f5 !important;
}
.flow-b-theme #flow-b button:hover {
  background-color: #281853 !important;
}
.flow-b-theme #flow-b #form-column {
  background: white;
}
.flow-b-theme #flow-b #form-column-wrapper {
  margin-left: 60px;
}
@media (max-width: 768px) {
  .flow-b-theme #flow-b #form-column-wrapper {
    margin: 0 auto;
    max-width: 314px;
    padding: 0;
  }
}
.flow-b-theme #flow-b #description-column {
  padding: 0 10px;
}
@media (max-width: 768px) {
  .flow-b-theme #flow-b #description-column {
    margin: 0 auto;
    max-width: 289px;
    padding: 0;
  }
}
.flow-b-theme #flow-b .ant-form-item-label label {
  color: black !important;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
}
@media (max-width: 768px) {
  .flow-b-theme #flow-b .ant-form-item-label label {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }
}
.flow-b-theme #flow-b .ant-typography-success {
  color: #52c41a !important;
}
.flow-b-theme #flow-b .anticon.anticon-trophy {
  color: #52c41a !important;
}
.flow-b-theme #flow-b .ant-form-item-label > label.ant-form-item-required:before {
  content: unset;
}
.flow-b-theme #flow-b .auth-sidebar-logo {
  margin-top: 68px;
  text-align: left;
}
.flow-b-theme #flow-b .auth-sidebar-logo img {
  height: 38px;
}
@media (max-width: 768px) {
  .flow-b-theme #flow-b .auth-sidebar-logo {
    margin-top: 14px;
  }
  .flow-b-theme #flow-b .auth-sidebar-logo img {
    height: 22px;
  }
}
.flow-b-theme #flow-b #progress-step {
  width: 234px;
  margin-top: 45px;
  font-size: 17px;
}
.flow-b-theme #flow-b #progress-step .ant-progress-bg {
  height: 18px !important;
  background: #72C140;
}
.flow-b-theme #flow-b #progress-step .ant-progress-inner {
  background: #D9D9D9;
}
@media (max-width: 768px) {
  .flow-b-theme #flow-b #progress-step {
    width: 128px;
    margin-top: 78px;
    font-size: 11px;
    line-height: 16px;
  }
  .flow-b-theme #flow-b #progress-step .ant-progress-bg {
    height: 6px !important;
  }
}
.flow-b-theme #flow-b button.next-step-btn {
  margin-top: 20px;
  width: 234px;
  height: 51px;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #FFFFFF;
}
@media (max-width: 768px) {
  .flow-b-theme #flow-b button.next-step-btn {
    width: 303px;
    height: 34px;
    margin-top: 4px;
    font-size: 15px;
    line-height: 22px;
    margin-left: 0px;
  }
}
.flow-b-theme #flow-b button.next-step-btn.step-2-btn {
  margin-top: 54px;
}
@media (max-width: 768px) {
  .flow-b-theme #flow-b button.next-step-btn.step-2-btn {
    margin-top: 44px;
  }
}
.flow-b-theme #flow-b div#login-link {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin-top: 60px;
  color: #000000;
}
@media (max-width: 768px) {
  .flow-b-theme #flow-b div#login-link {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    position: absolute;
    margin: 0;
    top: 53px;
  }
}
.flow-b-theme #flow-b .ant-list {
  margin-left: 3px;
}
.flow-b-theme #flow-b .testimonials {
  margin-top: 180px;
}
.flow-b-theme #flow-b .testimonials .ant-carousel {
  max-width: 90%;
  margin: 0 auto;
}
.flow-b-theme #flow-b .testimonials .ant-carousel h3 {
  height: 100%;
  text-align: center;
}
.flow-b-theme #flow-b .testimonials .ant-carousel .slick-dots li button {
  background: #F2F2F2 !important;
  border: 1px solid rgba(0, 0, 0, 0.26) !important;
  opacity: 0.4;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border: 1px solid #00000042;
  margin-top: 32px;
}
.flow-b-theme #flow-b .testimonials .ant-carousel .slick-dots li.slick-active button {
  opacity: 1;
  background: #F2F2F2;
  border: 1px solid rgba(0, 0, 0, 0.26);
}
.flow-b-theme #flow-b .testimonials .ant-carousel .slick-list .slick-slide {
  margin-top: 20px;
  background: #7414F5;
}
.flow-b-theme #flow-b .testimonials .ant-carousel .slick-list .slick-slide h3 {
  color: white;
  font-style: italic;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  margin-top: 46px;
  text-align: left;
}
@media (max-width: 768px) {
  .flow-b-theme #flow-b .testimonials .ant-carousel .slick-list .slick-slide h3 {
    margin-top: 30px;
  }
}
.flow-b-theme #flow-b .testimonials .ant-carousel .slick-list .slick-slide h4 {
  color: white;
  font-size: 17px;
  line-height: 26px;
  margin-left: 5px;
}
.flow-b-theme #flow-b .testimonials .ant-carousel .slick-list .slick-slide h4:first-of-type {
  font-weight: 600;
  margin-top: 50px;
}
.flow-b-theme #flow-b .testimonials-mobile {
  margin-top: 0px;
}
@media (min-width: 768px) {
  .flow-b-theme #flow-b .testimonials-mobile {
    display: none;
  }
}
.flow-b-theme #flow-b .testimonials-mobile .ant-carousel h3 {
  height: 100%;
  text-align: center;
}
.flow-b-theme #flow-b .testimonials-mobile .ant-carousel .slick-list .slick-slide {
  background: white;
}
.flow-b-theme #flow-b .testimonials-mobile .ant-carousel .slick-list .slick-slide h3 {
  color: #000000;
  font-style: italic;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
}
.flow-b-theme #flow-b .testimonials-mobile .ant-carousel .slick-list .slick-slide h4 {
  color: #000000;
  margin-left: 16px;
  font-size: 10px;
  line-height: 15px;
  margin-left: 60px;
  margin-top: 0;
  padding-bottom: 10px;
}
.flow-b-theme #flow-b .testimonials-mobile .ant-carousel .slick-list .slick-slide h4:first-of-type {
  font-weight: 600;
  margin-top: 20px;
  padding-bottom: 0px;
}
.flow-b-theme #flow-b .testimonials-mobile .ant-carousel .slick-dots li button {
  background: rgba(0, 0, 0, 0.32) !important;
  border: 1px solid rgba(0, 0, 0, 0.32) !important;
  opacity: 0.4;
  height: 7px;
  width: 7px;
  border-radius: 50%;
  border: 1px solid #00000052;
  margin-top: 35px;
}
.flow-b-theme #flow-b .testimonials-mobile .ant-carousel .slick-dots li.slick-active button {
  opacity: 1;
  background: rgba(0, 0, 0, 0.32);
  border: 1px solid rgba(0, 0, 0, 0.32);
}
.flow-b-theme #flow-b #signup-form h1 {
  margin-top: 90px;
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: 700;
  max-width: 456px;
  line-height: 45px;
  color: #000000;
}
@media (max-width: 768px) {
  .flow-b-theme #flow-b #signup-form h1 {
    font-size: 20px;
    line-height: 30px;
    margin-top: 22px;
    margin-left: 3px;
    width: 295px;
  }
}
.flow-b-theme #flow-b #signup-form h1.features-contain {
  margin-bottom: 8px;
}
.flow-b-theme #flow-b #signup-form h1.step-title {
  margin-top: 50px;
  margin-bottom: 40px;
}
@media (max-width: 768px) {
  .flow-b-theme #flow-b #signup-form h1.step-title {
    margin-top: 20px;
    margin-bottom: 33px;
  }
}
.flow-b-theme #flow-b #signup-form h1.step-3-title {
  margin-bottom: 4px;
}
.flow-b-theme #flow-b #signup-form .step-3-notice {
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  color: #000;
}
@media (min-width: 768px) {
  .flow-b-theme #flow-b #signup-form .step-3-notice {
    font-size: 13px;
    line-height: 20px;
  }
}
.flow-b-theme #flow-b #signup-form .step-2-form-item {
  margin-bottom: 8px !important;
}
.flow-b-theme #flow-b #signup-form .ant-form-item-control-input {
  background: #F2F2F2 !important;
  background-color: transparent !important;
}
@media (max-width: 768px) {
  .flow-b-theme #flow-b #signup-form .ant-form-item-control-input {
    width: 303px;
  }
}
.flow-b-theme #flow-b #signup-form .ant-form-item {
  max-width: 456px;
  margin-bottom: 10px;
}
@media (max-width: 768px) {
  .flow-b-theme #flow-b #signup-form .ant-form-item {
    margin: 0 auto;
    padding-bottom: 13px;
  }
}
.flow-b-theme #flow-b #signup-form .ant-form-item input {
  background: #F2F2F2 !important;
  color: #000000;
  height: 53px;
}
@media (max-width: 768px) {
  .flow-b-theme #flow-b #signup-form .ant-form-item input {
    height: 33px;
  }
}
.flow-b-theme #flow-b #signup-form .ant-form-item span {
  color: #000000;
}
@media (max-width: 768px) {
  .flow-b-theme #flow-b #signup-form .ant-checkbox-wrapper > span {
    font-weight: 400;
    font-size: 9px;
    line-height: 14px;
  }
}
.flow-b-theme #flow-b #signup-form span.ant-checkbox {
  top: 0px;
}
.flow-b-theme #flow-b #signup-form .ant-checkbox-inner {
  width: 9px;
  height: 9px;
}
.flow-b-theme #flow-b #signup-form .ant-checkbox-inner::after {
  transform: translate(10px, 10px);
}
.flow-b-theme #flow-b #signup-form .ant-select-selection-item {
  color: black !important;
  margin-top: 10px;
}
@media (max-width: 768px) {
  .flow-b-theme #flow-b #signup-form .ant-select-selection-item {
    margin-top: 0px;
  }
}
.flow-b-theme #flow-b #signup-form .ant-select-selector {
  background: #F2F2F2 !important;
  height: 55px;
}
@media (max-width: 768px) {
  .flow-b-theme #flow-b #signup-form .ant-select-selector {
    height: 35px;
  }
}
.flow-b-theme #flow-b #signup-form .ant-divider {
  height: 21px !important;
}
@media (max-width: 768px) {
  .flow-b-theme #flow-b #signup-form .ant-divider {
    height: 1px !important;
  }
}
.flow-b-theme #flow-b #signup-form .ant-input {
  box-shadow: none;
}
.flow-b-theme #flow-b #signup-form #features-text {
  font-weight: 400;
  font-size: 9px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.62);
  margin-bottom: 22px;
  margin-left: 1px;
}
@media (min-width: 768px) {
  .flow-b-theme #flow-b #signup-form #features-text {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 11px;
  }
}
.flow-b-theme #flow-b #signup-form .platform-buttons-wrapper {
  max-width: 505px;
}
@media (max-width: 768px) {
  .flow-b-theme #flow-b #signup-form .platform-buttons-wrapper {
    margin-top: 26px;
    max-width: 303px;
  }
}
.flow-b-theme #flow-b #signup-form .platform-buttons-wrapper .ant-input-affix-wrapper {
  border-color: #7979791a !important;
  background-color: #F2F2F2;
}
.flow-b-theme #flow-b #signup-form #email-credentials {
  margin: 30px 0;
}
.flow-b-theme #flow-b #signup-form #email-credentials .ant-form-item {
  max-width: 100%;
}
.flow-b-theme #flow-b #signup-form .platform-buttons {
  background-color: transparent !important;
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 34px;
  color: rgba(0, 0, 0, 0.65);
  height: 56px;
  background: #FFFFFF;
  border: 2px solid #F2F2F2;
  border-radius: 5px;
  margin-top: 28px;
}
.flow-b-theme #flow-b #signup-form .platform-buttons img {
  margin-bottom: 8px;
}
@media (max-width: 768px) {
  .flow-b-theme #flow-b #signup-form .platform-buttons {
    padding: 0px;
    font-size: 15px;
    line-height: 22px;
    height: 31px;
    margin-top: 20px;
  }
  .flow-b-theme #flow-b #signup-form .platform-buttons img {
    width: 17px;
    margin-bottom: 4px;
  }
}
.flow-b-theme #flow-b #signup-form .platform-buttons span.platform-buttons-logo-text {
  width: 340px;
}
@media (max-width: 768px) {
  .flow-b-theme #flow-b #signup-form .platform-buttons span.platform-buttons-logo-text {
    width: 200px;
  }
}
@media (max-width: 768px) {
  .flow-b-theme #flow-b #signup-form .platform-buttons:last-of-type {
    margin-top: 0px;
  }
}
.flow-b-theme #flow-b #signup-form .step-divider {
  max-width: 505px;
}
@media (max-width: 768px) {
  .flow-b-theme #flow-b #signup-form .step-divider {
    margin-top: 15px;
    max-width: 303px;
  }
}
.flow-b-theme #flow-b #signup-form .step-divider .ant-divider {
  height: 100px;
  border-color: #000;
}
.flow-b-theme #flow-b #signup-form .step-divider .ant-divider span {
  font-weight: 500;
  font-size: 25px;
  line-height: 38px;
  color: #000000;
}
@media (max-width: 768px) {
  .flow-b-theme #flow-b #signup-form .step-divider .ant-divider span {
    font-size: 15px;
    line-height: 2px;
  }
}
.flow-b-theme #flow-b .step {
  margin: 0 auto;
  max-width: 507px;
  padding: 0 10px;
}
.flow-b-theme #flow-b .step h1,
.flow-b-theme #flow-b .step h2,
.flow-b-theme #flow-b .step h3,
.flow-b-theme #flow-b .step h4,
.flow-b-theme #flow-b .step h5,
.flow-b-theme #flow-b .step h6,
.flow-b-theme #flow-b .step li {
  color: #fff;
}
.flow-b-theme #flow-b .step .ant-typography {
  color: #fff;
}
.flow-b-theme #flow-b #step1 h2 {
  margin-top: 188px;
  font-size: 35px;
  font-weight: 600;
  line-height: 52.5px;
}
.flow-b-theme #flow-b #step1 ul {
  margin-top: 65px;
}
.flow-b-theme #flow-b #step1 li {
  font-size: 25px;
  border: 0;
  padding-bottom: 0px;
}
.flow-b-theme #flow-b #step1 li::before {
  content: " \2022";
}
.flow-b-theme #flow-b #step2 .captera {
  width: 237px;
  background: white;
  border-radius: 20px 20px;
  padding: 25px;
  margin: 0 auto;
  margin-top: 120px;
}
.flow-b-theme #flow-b #step2 .captera img {
  width: 100%;
}
.flow-b-theme #flow-b #step3 {
  margin-top: 92px;
  margin-left: 63px;
}
.flow-b-theme #flow-b #step3 h3 {
  font-weight: 600;
  font-size: 35px;
  line-height: 52px;
}
.flow-b-theme #flow-b #step3 h2 {
  margin-top: 22px;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
}
.flow-b-theme #flow-b #step3 ul {
  margin-top: 28px;
}
.flow-b-theme #flow-b #step3 li {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  border: 0;
  padding-bottom: 0px;
  margin-top: 8px;
}
.flow-b-theme #flow-b #step3 li::before {
  content: " \2022";
}
.wallchart-leave-drawer .profile-column {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.wallchart-leave-drawer .profile-column div {
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
}
.wallchart-leave-drawer .profile-column div:nth-child(1) {
  flex-basis: 40px;
}
.wallchart-leave-drawer .profile-column div.profile-info {
  flex-basis: calc(100% - 40px);
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
}
.wallchart-leave-drawer .profile-column div.profile-info > div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.automation-seniority-entitlement-form .ant-input-number-disabled,
.automation-seniority-entitlement-form .ant-input-number-out-of-range input[disabled] {
  color: #535353;
}
.dark-theme .automation-seniority-entitlement-form .ant-input-number-disabled,
.dark-theme .automation-seniority-entitlement-form .ant-input-number-out-of-range input[disabled] {
  color: #878787 !important;
}
.addons-card {
  width: 420px;
  margin-bottom: 35px;
  margin-right: 30px;
  display: inline-block;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  padding: 18px;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  background: #fff;
  border-radius: 6px;
}
.addons-card.hoverable {
  cursor: pointer;
  transition: box-shadow 0.3s, border-color 0.3s;
}
.addons-card.hoverable:Hover {
  border-color: transparent;
  box-shadow: 0 1px 2px -2px #00000029, 0 3px 6px #0000001f, 0 5px 12px 4px #00000017;
}
.addons-card.disabled {
  cursor: not-allowed;
  opacity: 0.75;
}
.addons-card.automations {
  background-color: #d3e4f852;
}
.addons-card.addons {
  background-color: #f9eea847;
}
.addons-card.integrations {
  background-color: #a5f9dd29;
}
.addons-card.CANCELED {
  background-color: #e5c6c33d !important;
}
.addons-card-top-header {
  position: absolute;
  top: -12px;
}
.addons-card-top-header .ant-tag {
  margin-right: 0;
  font-weight: 500;
}
.addons-card-header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 12px;
}
.addons-card-header-icon {
  width: 75px;
  height: 70px;
  text-align: center;
  padding-top: 15px;
  background-color: #f0e2fe;
  border-radius: 5px;
}
.addons-card-header-icon svg {
  color: #7f00ff;
  height: 40px;
  width: auto;
}
.addons-card-header-extra {
  color: #a2a2a2;
  text-align: right;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-end;
}
.addons-card-header-extra .active {
  color: green;
}
.addons-card-header-extra .cancel-at-period-end {
  color: #fa8c15;
}
.addons-card-header-extra .trial-period {
  color: #003366;
}
.addons-card-header-extra .grant-period {
  color: #7f00ff;
}
.addons-card-header-extra .canceled {
  color: #ff4d4f;
}
.addons-card-title {
  color: #535353;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 10px;
}
.request-automation {
  background-color: #f0e2fe;
  padding: 15px;
  text-align: center;
}
.request-automation .ant-card-head {
  font-weight: 600;
  border-bottom: 0;
  padding-bottom: 10px;
  font-size: 16px;
}
.dark-theme .ant-card-bordered.request-automation {
  border-color: #7f00ff;
}
.dark-theme .addons-card {
  border-color: #e0e0e0;
  background-color: transparent;
}
.dark-theme .addons-card.hoverable:hover {
  border-color: #7f00ff;
}
.dark-theme .addons-card-title {
  color: #d1a3ff;
}
.dark-theme .addons-card-description {
  color: #e0e0e0;
}
.dark-theme .trial-period {
  color: #038fdd;
}
@media (max-width: 768px) {
  .addons-card {
    width: 100%;
    margin-right: 0;
  }
}
#report {
  position: relative;
}
#report #report-filters-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
#report .report-filter {
  margin-bottom: 10px;
}
#report .report-filter-clear {
  cursor: pointer;
}
#report .report-filter-clear span {
  color: #038fdd !important;
}
#report .ant-table-thead > tr > th {
  overflow-wrap: normal;
  white-space: break-spaces;
}
#report .ant-table-tbody > tr > td {
  min-width: 100px;
}
#report .ant-btn.prev-next-btns[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
#report #report-table-btns-container {
  text-align: left;
}
@media (min-width: 769px) {
  #report #report-table-btns-container {
    text-align: right;
    position: absolute;
    right: 0;
    top: 15px;
  }
}
#report .popover-trigger-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px dashed #d9d9d9;
  padding: 4px 12px;
  border-radius: 20px;
  transition: background-color 0.3s;
  display: inline-flex;
  margin-right: 16px;
}
#report .popover-trigger-container:hover {
  background-color: #f5f5f5;
}
#report .popover-trigger-container label {
  margin-left: 8px;
  margin-right: 20px;
  cursor: pointer;
  margin-bottom: 0;
}
#report .ant-table-sticky-scroll-bar {
  height: 10px !important;
  background-color: #262626 !important;
  border-radius: 4px !important;
}
#download-modal-btns {
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
  width: 120px;
}
#download-modal-btns button {
  margin: 5px;
}
.empty-data {
  text-align: left;
  background-color: #f5f5f5;
  padding: 20px;
}
@media (max-width: 768px) {
  .empty-data {
    padding: 0px;
  }
}
.dark-theme .empty-data {
  background-color: #48545d;
}
.user-leave-quotas .quota-box-container {
  display: flex;
  padding: 10px;
  align-items: stretch;
  column-gap: 5px;
  font-family: "NoirPro", sans-serif;
}
.user-leave-quotas .quota-box-container .quota-box {
  max-width: 200px;
  border: 1px solid #f0f0f0;
  border-radius: 6px;
  background: white;
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 5px;
  justify-content: flex-start;
  align-self: stretch;
  text-align: center;
}
@media screen and (max-width: 480px) {
  .user-leave-quotas .quota-box-container .quota-box {
    max-width: 100%;
  }
}
.user-leave-quotas .quota-box-container .quota-box .quota-box-value {
  text-align: center;
  font-size: 32px;
}
.user-leave-quotas .quota-box-container .quota-box .quota-box-title,
.user-leave-quotas .quota-box-container .quota-box .quota-box-footer {
  font-size: 14px;
  line-height: 20px;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.user-leave-quotas .quota-box-container .quota-formula {
  align-self: center;
}
.user-leave-quotas .quota-box-container .quota-formula .ant-typography {
  font-size: 26px;
}
.user-leave-quotas .quota-box-container .requested {
  border: 1px solid #7f00ff;
  color: #7f00ff;
}
.user-leave-quotas .quota-box-container .lock {
  position: relative;
}
.user-leave-quotas .quota-box-container .lock .quota-card-lock div {
  position: absolute;
  z-index: 1000;
  height: 100%;
  width: 100%;
  line-height: 100%;
  text-align: center;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  color: #7f00ff;
  align-items: center;
}
.user-leave-quotas .quota-box-container .lock .quota-box-title {
  opacity: 0.3;
}
.user-leave-quotas .quota-box-container .lock .quota-box-value {
  opacity: 0.3;
}
.user-leave-quotas .quota-box-container .lock .quota-box-footer {
  opacity: 0.3;
}
.user-leave-quotas-compact .quota-box-container {
  padding-left: 0;
  padding-right: 0;
}
.user-leave-quotas-compact .quota-box-container .quota-box-footer .ant-typography {
  margin-bottom: 0;
}
.dark-theme .user-leave-quotas .ant-menu-horizontal {
  background-color: transparent;
}
.dark-theme .quota-box {
  color: #E0E0E0 !important;
  background-color: #4e5c68 !important;
}
.dark-theme .requested {
  border: 1px solid #d1a3ff;
  color: #d1a3ff !important;
}
@media (max-width: 1100px) {
  .user-leave-quotas .quota-box-container .quota-box .quota-box-title {
    font-size: 14px;
    line-height: 18px;
  }
  .user-leave-quotas .quota-box-container .quota-box .quota-box-value {
    font-size: 26px;
  }
  .user-leave-quotas .quota-box-container .quota-box .quota-box-footer {
    line-height: 18px;
  }
  .user-leave-quotas .quota-box-container .quota-formula {
    align-self: center;
  }
  .user-leave-quotas .quota-box-container .quota-formula .ant-typography {
    font-size: 22px;
  }
}
@media (max-width: 800px) {
  .user-leave-quotas .quota-box-container .quota-box .quota-box-value {
    font-size: 20px;
  }
  .user-leave-quotas .quota-box-container .quota-formula .ant-typography {
    font-size: 20px;
  }
}
@media (max-width: 600px) {
  .user-leave-quotas .ant-card-head-wrapper {
    flex-wrap: wrap;
  }
  .user-leave-quotas .ant-card-head-wrapper .ant-card-head-title {
    flex: none;
    width: 100%;
  }
  .user-leave-quotas .ant-card-head-wrapper .ant-card-extra {
    flex: none;
    width: 100%;
    padding-top: 0px;
  }
  .user-leave-quotas .quota-box-container {
    padding: 5px 0px;
  }
  .user-leave-quotas .quota-box-container .quota-box .quota-box-title {
    line-height: 16px;
    font-size: 12px;
  }
  .user-leave-quotas .quota-box-container .quota-box .quota-box-value {
    font-size: 16px;
  }
  .user-leave-quotas .quota-box-container .quota-box .quota-box-footer {
    line-height: 16px;
    font-size: 12px;
  }
  .user-leave-quotas .quota-box-container .quota-formula .ant-typography {
    font-size: 18px;
  }
  .user-leave-quotas .ant-card-body {
    padding: 5px 0px !important;
  }
}
@media (max-width: 480px) {
  .user-leave-quotas .quota-box-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .user-leave-quotas .quota-formula {
    font-size: 18px;
    margin-left: 0px;
    align-self: auto;
  }
}
@media only screen and (min-width: 600px) {
  #userHistoryLeaves #userLeaveFilters {
    position: absolute;
    right: 0px;
    top: -52px;
    z-index: 9999;
  }
}
#userHistoryLeaves .leave-history-filter {
  width: 120px;
  margin: 6px;
}
@media only screen and (max-width: 600px) {
  #userHistoryLeaves .leave-history-filter {
    display: flex;
    width: 280px;
  }
}
@media only screen and (max-width: 600px) {
  #userHistoryLeaves label {
    margin-left: 10px;
  }
}
@media only screen and (max-width: 768px) {
  .vt-table-wrapper-user-page .actions button {
    width: 100%;
  }
  .vt-table-wrapper-user-page .actions button:not(:last-child) {
    margin-bottom: 10px;
  }
}
.select-action-menu .ant-select-selector {
  background-color: #7f00ff !important;
}
.select-action-menu .ant-select-selector .ant-select-selection-placeholder {
  color: #ffffff !important;
}
.select-action-menu .ant-select-arrow .anticon svg {
  fill: #ffffff !important;
}
@media (max-width: 768px) {
  .filters {
    width: 100%;
  }
  .vt-filters-wrapper {
    width: 100%;
  }
  .vt-filters-wrapper > div {
    flex-wrap: wrap;
  }
  .vt-filters-wrapper > div > div {
    width: 100%;
  }
  .vt-filters-wrapper #parent-select {
    display: flex;
    align-items: center;
  }
  .vt-filters-wrapper #parent-select .ant-select:first-child {
    margin-left: 0px !important;
    width: 40% !important;
  }
  .vt-filters-wrapper #parent-select .ant-select:last-child {
    width: 54% !important;
  }
}
@media (max-width: 768px) {
  .vt-callendar-sync-page .main-content-header {
    margin-bottom: 5px;
  }
  .vt-callendar-sync-page .empty-data {
    margin-top: 20px;
  }
  .vt-callendar-sync-page .ant-form-item {
    margin-bottom: 15px;
  }
}
.my-addons-title {
  display: flex;
  justify-content: space-between;
}
.popup-news-modal {
  top: 30px;
}
.popup-news-modal .news-type {
  font-weight: 400;
  margin-top: 0.5em;
}
.popup-news-modal h2.title {
  margin-top: 0.4em;
}
.popup-news-modal .ant-modal-close {
  background-color: #7f00ff;
  color: #ffffff;
  border: 1px solid #ffffff;
}
.popup-news-modal .ant-modal-close:hover {
  background-color: #ffffff;
}
.open-api-documentation-card {
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  padding: 24px;
  margin-bottom: 15px;
}
.open-api-documentation-card h2 {
  margin-top: 0;
}
.open-api-buy-card {
  background-color: #f0e2fe;
  border-radius: 6px;
  padding: 24px;
  margin-bottom: 15px;
}
.open-api-buy-card h2 {
  margin-top: 0;
  margin-bottom: 24px;
}
.open-api-buy-card p {
  margin-bottom: 20px;
}
.dark-theme .open-api-buy-card {
  background-color: transparent;
  border: 1px solid #e0e0e0;
}
#vt-cards .vt-cards-intro {
  margin-bottom: 30px;
}
#vt-cards .vt-cards-container {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
}
@media (min-width: 769px) {
  #vt-cards .vt-cards-container {
    justify-content: flex-start;
  }
}
#vt-cards .vt-card {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 230px;
  width: 300px;
}
#vt-cards .vt-card-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
#vt-cards .vt-card .ant-card-body {
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
}
#vt-cards .vt-card .ant-card-head-title {
  color: #7f00ff !important;
}
#vt-cards .vt-card .ant-card-actions li {
  color: #7f00ff;
}
#vt-cards .vt-card .ant-card-actions div[data-disabled="true"] {
  cursor: not-allowed;
}
#vt-cards .cards-feedback {
  text-decoration: none;
  margin-top: 30px;
}
#vt-cards .cards-feedback button {
  margin-left: 15px;
}
.dark-theme #vt-cards .vt-card .ant-card-head-title {
  color: white !important;
}
.dark-theme #vt-cards .vt-card .ant-card-actions li {
  color: white !important;
}
.custom-time-range-picker {
  display: flex;
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  transition: border-color 0.3s ease;
}
.custom-time-range-picker:hover {
  border-color: #7f00ff;
}
.time-select {
  flex: 1;
}
.time-select .ant-select-selector {
  border: none !important;
  box-shadow: none !important;
}
.end-time-container {
  display: flex;
  align-items: center;
  position: relative;
}
.clock-icon {
  padding-left: 3px;
  transition: color 0.3s ease;
  color: #00000040;
}
.clear-icon {
  padding-left: 3px;
  transition: color 0.3s ease;
  color: #00000073;
}
/*Base Styles*/
body.dark-theme a {
  color: #d1a3ff !important;
}
body.dark-theme.framed-layout,
body.dark-theme.boxed-layout {
  background-color: #22282e;
}
body.dark-theme .ant-layout-sider {
  color: #E0E0E0 !important;
  background-color: #343e44;
}
body.dark-theme .ant-layout-sider .layout-sidebar-content {
  border-right-color: #4e5c68 !important;
}
body.dark-theme .profile-button-name-wrapper .profile-button-name {
  color: #ededed !important;
}
body.dark-theme .ant-form-item-has-error .ant-select-selector {
  background: #4e5c68 !important;
}
body.dark-theme .ant-picker-content .ant-picker-time-panel-cell-disabled .ant-picker-time-panel-cell-inner {
  background-color: #434f5a !important;
}
body.dark-theme .no-border-button {
  background-color: #434f5a !important;
}
body.dark-theme .requested {
  border: 1px solid #d1a3ff;
  color: #d1a3ff !important;
}
body.dark-theme .invite-users-modal-header {
  background-color: #4e5c68;
  padding: 15px;
}
body.dark-theme .ant-popover-content .ant-popover-inner .ant-typography {
  color: #878787 !important;
}
body.dark-theme .ant-list .ant-spin-nested-loading .ant-spin-container .ant-list-items li {
  color: #E0E0E0;
}
body.dark-theme .ant-spin-container .ant-typography {
  color: #E0E0E0;
}
body.dark-theme .ant-select-item-option-disabled.ant-select-item-option-selected {
  background: none;
}
body.dark-theme .auth-sidebar-capterra .ant-typography {
  color: #E0E0E0;
}
body.dark-theme .vt-primary-color {
  color: #d1a3ff;
}
body.dark-theme .row-dragging {
  background: #38424B;
  border: 1px solid #ccc;
}
body.dark-theme .row-dragging td {
  padding: 16px;
  visibility: hidden;
}
body.dark-theme .row-dragging .drag-visible {
  visibility: visible;
}
body.dark-theme .clear-icon {
  color: #E0E0E0;
}
body.dark-theme .clock-icon {
  color: #E0E0E0;
}
body.dark-theme .ant-notification-notice-with-icon .ant-notification-notice-description .ant-typography {
  color: #E0E0E0 !important;
}
body.dark-theme .steps-content {
  background-color: #42505a !important;
}
body.dark-theme .steps-content a {
  color: #bf80ff !important;
}
body.dark-theme .steps-content .import-users-step .ant-card-body {
  background-color: #495862 !important;
}
body.dark-theme .user-logs-card a,
body.dark-theme .ant-timeline-item-content a {
  color: #bf80ff !important;
}
body.dark-theme .ant-drawer-content {
  background-color: #38424B;
}
body.dark-theme .layout-sider-header {
  background-color: #38424B;
}
body.dark-theme .layout-sider-header .site-logo {
  color: white;
}
body.dark-theme .changelog {
  color: #E0E0E0;
}
body.dark-theme .changelog .anticon svg {
  fill: #E0E0E0;
}
body.dark-theme .changelog:hover {
  color: #bf80ff !important;
}
body.dark-theme .changelog:hover .anticon svg {
  fill: #bf80ff !important;
}
body.dark-theme .layout-sider-scrollbar .ant-menu-item-selected span a {
  color: #d1a3ff !important;
}
body.dark-theme .layout-sider-scrollbar .ant-menu-submenu,
body.dark-theme .layout-sider-scrollbar .ant-menu-item {
  color: #E0E0E0 !important;
}
body.dark-theme .layout-sider-scrollbar .ant-menu-submenu:hover,
body.dark-theme .layout-sider-scrollbar .ant-menu-item:hover,
body.dark-theme .layout-sider-scrollbar .ant-menu-submenu-selected,
body.dark-theme .layout-sider-scrollbar .ant-menu-item-selected,
body.dark-theme .layout-sider-scrollbar .ant-menu-submenu-active,
body.dark-theme .layout-sider-scrollbar .ant-menu-item-active {
  color: #d1a3ff !important;
}
body.dark-theme .layout-sider-scrollbar .ant-menu-submenu:hover > .ant-menu-title-content .anticon > svg,
body.dark-theme .layout-sider-scrollbar .ant-menu-item:hover > .ant-menu-title-content .anticon > svg,
body.dark-theme .layout-sider-scrollbar .ant-menu-submenu-selected > .ant-menu-title-content .anticon > svg,
body.dark-theme .layout-sider-scrollbar .ant-menu-item-selected > .ant-menu-title-content .anticon > svg,
body.dark-theme .layout-sider-scrollbar .ant-menu-submenu-active > .ant-menu-title-content .anticon > svg,
body.dark-theme .layout-sider-scrollbar .ant-menu-item-active > .ant-menu-title-content .anticon > svg {
  fill: #d1a3ff !important;
}
body.dark-theme .layout-sider-scrollbar .ant-menu-item a {
  color: #E0E0E0 !important;
}
body.dark-theme .layout-sider-scrollbar .ant-menu-item a:hover {
  color: #bf80ff !important;
}
body.dark-theme .app-layout {
  background: #38434b;
}
body.dark-theme .app-layout .content-layout > .ant-layout-content .main-content {
  background-color: #42505a;
}
body.dark-theme .ant-menu-item button {
  border-color: #E0E0E0;
  color: #E0E0E0;
}
body.dark-theme .ant-menu-item button:hover {
  color: #bf80ff !important;
  border-color: #bf80ff !important;
}
body.dark-theme .ant-card-head .ant-card-extra {
  color: #E0E0E0 !important;
}
body.dark-theme .ant-card-head .ant-card-head-wrapper .ant-card-head-title a {
  color: #d1a3ff !important;
}
body.dark-theme .ant-card-head .ant-card-head-wrapper .ant-card-head-title a:hover {
  color: #bf80ff !important;
}
body.dark-theme .ant-card-head .ant-card-head-wrapper .ant-row .ant-col {
  color: #E0E0E0;
}
body.dark-theme .main-content .ant-result .ant-result-title {
  color: #E0E0E0 !important;
}
body.dark-theme .main-content .ant-result-extra div {
  color: #E0E0E0 !important;
}
body.dark-theme .ai-assistant-chat-conversation-message-assistant {
  color: #E0E0E0 !important;
  background-color: #4e5c68;
}
body.dark-theme .ai-assistant-chat-conversation-message-user {
  color: #E0E0E0 !important;
  background-color: #38424B;
}
body.dark-theme .ai-assistant-chat-footer {
  background-color: #42505a;
  color: #38424B;
}
body.dark-theme .ai-assistant-chat-footer .ant-input-group-wrapper {
  background-color: #4e5c68;
  color: #38424B;
}
body.dark-theme .ai-assistant-conversation-list-timestamp {
  font-size: 14px;
  color: #878787;
}
body.dark-theme .ai-assistant-chat-header {
  background-color: #42505a60;
  backdrop-filter: blur(13px);
}
body.dark-theme .main-content .main-content-body .ant-btn,
body.dark-theme .main-content .main-content-body button {
  background-color: #4e5c68;
  color: #E0E0E0 !important;
  border-color: #E0E0E0 !important;
}
body.dark-theme .main-content .main-content-body .ant-btn:hover,
body.dark-theme .main-content .main-content-body button:hover {
  color: #bf80ff !important;
  border-color: #bf80ff !important;
  background-color: #38424B;
}
body.dark-theme .main-content .main-content-body .ant-btn.ant-btn-dangerous,
body.dark-theme .main-content .main-content-body button.ant-btn-dangerous {
  border-color: #f5222d !important;
  color: #f5222d !important;
}
body.dark-theme .main-content .main-content-body .ant-typography-danger {
  color: #f5222d !important;
}
body.dark-theme .ant-form-item-control-input {
  color: #E0E0E0 !important;
  background-color: #42505a !important;
}
body.dark-theme .stripe-input .ant-form-item-control-input {
  color: #E0E0E0 !important;
  background-color: #42505a !important;
}
body.dark-theme .billing-body {
  color: #E0E0E0 !important;
}
body.dark-theme .billing-body a {
  color: #d1a3ff !important;
}
body.dark-theme .billing-body a:hover {
  color: #bf80ff !important;
}
body.dark-theme .ant-input-number-group .ant-input-number .ant-input-number-handler-wrap {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
body.dark-theme .ant-input-number-group .ant-input-number-group-addon {
  background-color: #48545d;
  border-color: #4d5860;
}
body.dark-theme .ant-tabs-nav-list div.ant-tabs-tab-active div.ant-tabs-tab-btn {
  color: #d1a3ff !important;
}
body.dark-theme .ant-tabs-extra-content a {
  color: #d1a3ff !important;
}
body.dark-theme .ant-tabs-extra-content a:hover {
  color: #bf80ff !important;
}
body.dark-theme .ant-tabs a .name {
  color: #d1a3ff !important;
}
body.dark-theme .ant-tabs a .name:hover {
  color: #bf80ff !important;
}
body.dark-theme .ant-tabs-dropdown .ant-tabs-dropdown-menu,
body.dark-theme .ant-tabs-dropdown .ant-tabs-dropdown-menu-item {
  color: #E0E0E0 !important;
  background-color: #38424B !important;
  border-color: #495862 !important;
}
body.dark-theme .ant-form-item-explain-error {
  color: #f5222d !important;
}
body.dark-theme .ant-form-item-control-input .ant-radio-button-wrapper-checked {
  color: #d1a3ff !important;
  border-color: #d1a3ff !important;
}
body.dark-theme .price-plan-card {
  background-color: #647585 !important;
}
body.dark-theme .price-plan-card .card-body {
  color: #E0E0E0 !important;
}
body.dark-theme .price-plan-card .card-body .anticon svg {
  fill: #E0E0E0 !important;
}
body.dark-theme .info-tooltip svg {
  fill: #d1a3ff !important;
}
body.dark-theme .ant-tooltip .ant-tooltip-arrow:before {
  background-color: #4e5c68;
}
body.dark-theme .ant-tooltip .ant-tooltip-inner {
  background-color: #4e5c68;
}
body.dark-theme .ant-tooltip.ant-menu-inline-collapsed-tooltip .ant-tooltip-arrow::after,
body.dark-theme .ant-tooltip.ant-menu-inline-collapsed-tooltip .ant-tooltip-arrow::before,
body.dark-theme .ant-tooltip.ant-menu-inline-collapsed-tooltip .ant-tooltip-inner {
  background-color: #4c555c !important;
}
body.dark-theme .ant-modal-body .ant-row .ant-col .info-tooltip {
  color: #d1a3ff !important;
}
body.dark-theme .ant-modal-body .ant-row .ant-col .info-tooltip svg {
  fill: #d1a3ff !important;
}
body.dark-theme .ant-modal-body .ant-typography {
  color: white !important;
}
body.dark-theme .ant-modal-body .ant-typography-secondary {
  color: #a1a1a1 !important;
}
body.dark-theme .ant-statistic .ant-statistic-content {
  color: #E0E0E0 !important;
}
body.dark-theme .ant-statistic .ant-statistic-content .ant-typography {
  color: #E0E0E0 !important;
}
body.dark-theme .ant-statistic .ant-statistic-content .ant-typography svg {
  fill: #d1a3ff !important;
}
body.dark-theme .import-page .ant-result .ant-result-icon .anticon {
  color: green;
}
body.dark-theme .ant-modal-footer .ant-btn-dangerous {
  border-color: #f5222d !important;
  color: #f5222d !important;
}
body.dark-theme .ant-card-body {
  color: #E0E0E0 !important;
}
body.dark-theme .ant-card-body .ant-row .ant-space .ant-space-item .ant-typography {
  color: #E0E0E0 !important;
}
body.dark-theme .ant-card-body .ant-row .ant-space .ant-space-item .ant-typography strong {
  color: #878787 !important;
}
body.dark-theme .ant-card-body .ant-row strong {
  color: #878787 !important;
}
body.dark-theme .ant-card-body .ant-statistic-content {
  color: #E0E0E0 !important;
}
body.dark-theme .ant-card-body .ant-statistic-content .ant-typography {
  color: #E0E0E0 !important;
}
body.dark-theme .ant-card-body .ant-statistic-content .ant-typography svg {
  fill: #d1a3ff !important;
}
body.dark-theme .ant-card-body .ant-card-extra {
  color: #E0E0E0 !important;
}
body.dark-theme .ant-card-body .ant-descriptions .ant-descriptions-item-label {
  color: #E0E0E0 !important;
}
body.dark-theme .ant-card-body .ant-descriptions .ant-descriptions-item-content {
  color: #878787 !important;
}
body.dark-theme .ant-card-body .ant-descriptions .ant-descriptions-item-content a {
  color: #d1a3ff !important;
}
body.dark-theme .ant-card-body .ant-descriptions .ant-descriptions-item-content a:hover {
  color: #bf80ff !important;
}
body.dark-theme .ant-card-body .ant-tabs .ant-tabs-tab-active div.ant-tabs-tab-btn {
  color: #d1a3ff !important;
}
body.dark-theme .ant-card-body .ant-tabs .leaves-box .leaves-info .leaves-text .ant-space-item .ant-typography {
  color: #E0E0E0 !important;
}
body.dark-theme .ant-card-body .ant-tabs .leaves-box .leaves-info .leaves-text .ant-space-item .ant-typography-secondary {
  color: #878787 !important;
}
body.dark-theme .ant-card-body .ant-tabs .leaves-box a {
  color: #d1a3ff !important;
}
body.dark-theme .ant-card-body .ant-tabs .leaves-box a:hover {
  color: #bf80ff !important;
}
body.dark-theme .ant-card-body .ant-typography {
  color: #E0E0E0 !important;
}
body.dark-theme .clickable-table .ant-table table {
  border-spacing: 0 1px;
}
body.dark-theme .clickable-table .ant-table table .ant-table-tbody .action button {
  transition: transform 0.2s;
}
body.dark-theme .clickable-table .ant-table table .ant-table-tbody tr.ant-table-row.clickable-table-error:hover td {
  background-color: #444444 !important;
}
body.dark-theme .clickable-table .ant-table table .ant-table-tbody .action button:hover {
  transition: transform 0.2s;
  transform: scale(1.3);
}
body.dark-theme .clickable-table .ant-table table .ant-table-tbody tr:hover {
  z-index: 10;
  -webkit-box-shadow: 0px 0px 2px 0.5px rgba(127, 0, 255, 0.6);
  box-shadow: 0px 0px 2px 0.5px rgba(127, 0, 255, 0.6);
  cursor: pointer;
}
body.dark-theme .clickable-table .clickable-table-error {
  background-color: #444444;
  opacity: 0.6;
}
body.dark-theme .ant-table-content a {
  color: #d1a3ff !important;
}
body.dark-theme .ant-table-content a:hover {
  color: #bf80ff !important;
}
body.dark-theme .ant-table-content .ant-table-cell .ant-switch-checked {
  background-color: #7f00ff !important;
}
body.dark-theme .ant-table-content .ant-table-cell button {
  background-color: #38424B !important;
}
body.dark-theme .calendar-body a {
  color: #E0E0E0;
}
body.dark-theme .calendar-body .fc .fc-header-toolbar .fc-toolbar-chunk .fc-today-button {
  background-color: #38424B !important;
}
body.dark-theme .calendar-body .fc .fc-view-harness .fc-day-past {
  background-color: #4e5c68;
}
body.dark-theme .calendar-body .fc .fc-view-harness .fc-day-past .fc-daygrid-day-number,
body.dark-theme .calendar-body .fc .fc-view-harness .fc-day-past .fc-list-day-cushion {
  background-color: #4e5c68;
  color: #E0E0E0;
}
body.dark-theme .calendar-body .fc .fc-view-harness .fc-day-today .fc-daygrid-day-number {
  color: #878787;
}
body.dark-theme .calendar-body .fc .fc-view-harness .fc-day-today .fc-daygrid-more-link {
  color: #878787;
}
body.dark-theme .calendar-body .fc .fc-view-harness .fc-day-today .fc-daygrid-day-bg {
  width: calc(100% - 10px);
  height: 100%;
  display: block;
  background-color: #e7b3ff;
  top: 3px;
  position: absolute;
  left: 5px;
}
body.dark-theme .calendar-body .fc .fc-view-harness .fc-day-today .fc-daygrid-day-number {
  border-color: #7e01ff;
  color: #7f00ff !important;
}
body.dark-theme .calendar-body .fc .fc-view-harness .fc-day-today .fc-list-day-cushion {
  background-color: #f5ebff;
}
body.dark-theme .calendar-body .fc .fc-view-harness .fc-day-today .fc-list-day-cushion a {
  border-color: #7e01ff;
  color: #38424B;
}
body.dark-theme .calendar-body .fc .fc-view-harness .fc-day-future .fc-list-day-cushion,
body.dark-theme .calendar-body .fc .fc-view-harness .fc-day-future .fc-list-day-cushion:hover {
  background-color: #4e5c68;
}
body.dark-theme .calendar-body .fc .fc-view-harness .fc-popover.fc-day {
  color: #E0E0E0;
  background-color: #38424B;
  border-color: #bf80ff;
}
body.dark-theme .calendar-body .fc .fc-button-primary:hover,
body.dark-theme .calendar-body .fc .fc-button-primary:active {
  background-color: #38424B !important;
}
body.dark-theme .calendar-body .fc .fc-button-active:not(active) {
  background-color: #38424B !important;
  color: #E0E0E0;
}
body.dark-theme .calendar-body .fc .fc-list-event:hover td {
  background-color: #38424B;
}
body.dark-theme .calendar-body.user-not-work-day-mon .fc tbody .fc-daygrid-body .fc-day-mon {
  background: repeating-linear-gradient(45deg, #4e5c68, #4e5c68, 10px, #47545f 10px, #47545f 20px);
}
body.dark-theme .calendar-body.user-not-work-day-tue .fc tbody .fc-daygrid-body .fc-day-tue {
  background: repeating-linear-gradient(45deg, #4e5c68, #4e5c68, 10px, #47545f 10px, #47545f 20px);
}
body.dark-theme .calendar-body.user-not-work-day-wed .fc tbody .fc-daygrid-body .fc-day-wed {
  background: repeating-linear-gradient(45deg, #4e5c68, #4e5c68, 10px, #47545f 10px, #47545f 20px);
}
body.dark-theme .calendar-body.user-not-work-day-thu .fc tbody .fc-daygrid-body .fc-day-thu {
  background: repeating-linear-gradient(45deg, #4e5c68, #4e5c68, 10px, #47545f 10px, #47545f 20px);
}
body.dark-theme .calendar-body.user-not-work-day-fri .fc tbody .fc-daygrid-body .fc-day-fri {
  background: repeating-linear-gradient(45deg, #4e5c68, #4e5c68, 10px, #47545f 10px, #47545f 20px);
}
body.dark-theme .calendar-body.user-not-work-day-sat .fc tbody .fc-daygrid-body .fc-day-sat {
  background: repeating-linear-gradient(45deg, #4e5c68, #4e5c68, 10px, #47545f 10px, #47545f 20px);
}
body.dark-theme .calendar-body.user-not-work-day-sun .fc tbody .fc-daygrid-body .fc-day-sun {
  background: repeating-linear-gradient(45deg, #4e5c68, #4e5c68, 10px, #47545f 10px, #47545f 20px);
}
body.dark-theme .calendar-body.start-week-mon-fri .fc-header-toolbar .fc-toolbar-chunk .fc-button-group button.fc-changeWeekMonFri-button {
  color: #bf80ff;
  background-color: #38424B;
  border-color: #bf80ff;
}
body.dark-theme .calendar-body.start-week-sun-thu .fc-header-toolbar .fc-toolbar-chunk .fc-button-group button.fc-changeWeekSunThu-button {
  color: #bf80ff;
  background-color: #38424B;
  border-color: #bf80ff;
}
body.dark-theme .calendar-body.calendar-view-listMonth .fc-header-toolbar .fc-toolbar-chunk .fc-button-group button.fc-changeViewList-button {
  color: #bf80ff;
  background-color: #38424B;
  border-color: #bf80ff;
}
body.dark-theme .calendar-body.calendar-view-dayGridMonth .fc-header-toolbar .fc-toolbar-chunk .fc-button-group button.fc-changeViewMonth-button {
  color: #bf80ff;
  background-color: #38424B;
  border-color: #bf80ff;
}
body.dark-theme .paragraph-with-top-divider {
  border-color: #495862;
}
body.dark-theme .ant-notification-notice {
  background-color: #4e5c68 !important;
  color: #E0E0E0 !important;
}
body.dark-theme .ant-notification-notice .ant-notification-notice-content .ant-notification-notice-description,
body.dark-theme .ant-notification-notice .ant-notification-notice-content .ant-notification-notice-message {
  background-color: #4e5c68 !important;
  color: #E0E0E0 !important;
}
body.dark-theme .ant-alert a,
body.dark-theme .ant-alert-with-description a {
  color: #bf80ff;
}
body.dark-theme .ant-select-item-empty {
  color: #878787 !important;
}
body.dark-theme .ant-select-disabled .ant-select-selector {
  background-color: #343e44 !important;
  border: 1px solid #38434b !important;
}
body.dark-theme .ant-select-disabled .ant-select-selection-item {
  color: #878787 !important;
}
body.dark-theme .ant-select-selection-item {
  color: #E0E0E0 !important;
}
body.dark-theme .ant-table-placeholder {
  color: #878787 !important;
}
body.dark-theme .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-right-color: #E0E0E0 !important;
}
body.dark-theme .auth-sidebar {
  background-color: #4e5c68;
}
body.dark-theme .auth-sidebar .ant-typography {
  color: #E0E0E0 !important;
}
body.dark-theme .auth-sidebar-content {
  background-color: #4e5c68;
  color: #E0E0E0;
}
body.dark-theme .auth-sidebar-content a {
  color: #bf80ff;
}
body.dark-theme .ant-descriptions-view .ant-descriptions-item-label {
  color: #E0E0E0 !important;
}
body.dark-theme .ant-descriptions-view .ant-descriptions-item-content {
  color: #E0E0E0 !important;
}
.dark-theme {
  color: #E0E0E0;
  background-color: #38424B;
}
.dark-theme h1,
.dark-theme h2,
.dark-theme h3,
.dark-theme h4,
.dark-theme h5,
.dark-theme h6,
.dark-theme .h1,
.dark-theme .h2,
.dark-theme .h3,
.dark-theme .h4,
.dark-theme .h5,
.dark-theme .h6 {
  color: #ededed;
}
.dark-theme .ant-modal h1,
.dark-theme .ant-modal h2,
.dark-theme .ant-modal h3,
.dark-theme .ant-modal h4,
.dark-theme .ant-modal h5,
.dark-theme .ant-modal h6,
.dark-theme .ant-modal .h1,
.dark-theme .ant-modal .h2,
.dark-theme .ant-modal .h3,
.dark-theme .ant-modal .h4,
.dark-theme .ant-modal .h5,
.dark-theme .ant-modal .h6 {
  color: #38424B;
}
.dark-theme .auth-wrap {
  background-color: #4e5c68;
}
.dark-theme .ant-drawer-right .ant-drawer-content-wrapper,
.dark-theme .ant-drawer-right .ant-drawer-content {
  background: #38424B;
}
.dark-theme .ant-drawer-close {
  color: #E0E0E0;
}
.dark-theme .ant-drawer-close:hover,
.dark-theme .ant-drawer-close:focus {
  color: #ffffff;
}
.dark-theme .ant-layout,
.dark-theme .help-drawer-inner,
.dark-theme .ant-layout.ant-layout-has-sider > .ant-layout {
  background: #38424B;
}
.dark-theme .ant-layout-header {
  background: #38424B;
  color: #E0E0E0;
}
.dark-theme .ant-layout-header button {
  background-color: #4e5c68;
}
.dark-theme .ant-layout-header button.joyride-start {
  background-color: #4e5c68 !important;
  border-color: #E0E0E0 !important;
}
.dark-theme .ant-layout-header button.joyride-start:hover {
  border-color: #bf80ff !important;
  color: #bf80ff !important;
}
.dark-theme .ant-layout-footer {
  background: #38434b;
  color: #E0E0E0;
  border-top: 1px solid #495862;
}
.dark-theme .ant-menu {
  color: #E0E0E0;
  background: #343d45;
}
.dark-theme .ant-menu-inline,
.dark-theme .ant-menu-vertical,
.dark-theme .ant-menu-vertical-left {
  border-right-color: #495862;
}
.dark-theme .ant-menu-dark {
  color: #E0E0E0;
  background: #343d45;
}
.dark-theme .ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.dark-theme .ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
  background: #ffffff;
}
.dark-theme .ant-menu-submenu-selected .ant-menu-submenu-title {
  color: #bf80ff !important;
}
.dark-theme .ant-menu-submenu-selected .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.dark-theme .ant-menu-submenu-selected .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
  background: #bf80ff !important;
}
.dark-theme .ant-menu-submenu-title:hover {
  color: #d1a3ff;
}
.dark-theme .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.dark-theme .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before {
  background: #bf80ff !important;
}
.dark-theme .ant-menu-submenu-title:hover svg {
  fill: #d1a3ff !important;
}
.dark-theme .ant-menu-dark .ant-menu-submenu-selected .ant-menu-sub .ant-menu-submenu-title {
  color: #E0E0E0;
}
.dark-theme .ant-menu-dark .ant-menu-submenu-selected .ant-menu-sub .ant-menu-submenu-title:hover {
  color: #d1a3ff;
}
.dark-theme .ant-menu-dark .ant-menu-sub {
  color: #E0E0E0;
  background: none;
}
.dark-theme .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.dark-theme .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
  background: #ffffff;
}
.dark-theme .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.dark-theme .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before {
  background: #d1a3ff;
}
.dark-theme .ant-menu-item > a {
  color: #E0E0E0;
}
.dark-theme .ant-menu-item > a:hover {
  color: #d1a3ff;
}
.dark-theme .ant-menu-item-selected > a:hover {
  color: #d1a3ff;
}
.dark-theme .ant-menu-dark.ant-menu-submenu-popup,
.dark-theme .ant-menu-submenu-popup {
  background: #38424B;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.dark-theme .ant-menu-dark.ant-menu-submenu-popup .ant-menu-sub .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.dark-theme .ant-menu-dark.ant-menu-submenu-popup .ant-menu-sub .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before {
  background: #ffffff;
}
.dark-theme .ant-menu-submenu-popup.ant-menu-light .ant-menu-submenu-open > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.dark-theme .ant-menu-submenu-popup.ant-menu-light .ant-menu-submenu-open > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  background: #d1a3ff;
}
.dark-theme .ant-menu-dark.ant-menu-horizontal {
  border-bottom-color: #343d45;
}
.dark-theme .ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
.dark-theme .ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
  border-color: #343d45;
}
.dark-theme .ant-menu-horizontal {
  border-bottom-color: #495862;
  background: #38424B;
}
.dark-theme .ant-menu-horizontal > .ant-menu-item:hover,
.dark-theme .ant-menu-horizontal > .ant-menu-submenu:hover,
.dark-theme .ant-menu-horizontal > .ant-menu-item-active,
.dark-theme .ant-menu-horizontal > .ant-menu-submenu-active,
.dark-theme .ant-menu-horizontal > .ant-menu-item-open,
.dark-theme .ant-menu-horizontal > .ant-menu-submenu-open,
.dark-theme .ant-menu-horizontal > .ant-menu-item-selected,
.dark-theme .ant-menu-horizontal > .ant-menu-submenu-selected {
  border-bottom-color: #E0E0E0;
  color: #E0E0E0;
}
.dark-theme .ant-menu-horizontal > .ant-menu-item > a,
.dark-theme .ant-menu-horizontal > .ant-menu-submenu > a {
  color: #E0E0E0;
}
.dark-theme .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #4d5d69;
  color: #E0E0E0;
}
.dark-theme .ant-layout-sider .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgba(255, 255, 255, 0.12);
}
.dark-theme .ant-menu-submenu > .ant-menu,
.dark-theme .ant-menu-item:active,
.dark-theme .ant-menu-submenu-title:active {
  background-color: #4d5d69;
  color: #E0E0E0;
}
.dark-theme .ant-layout-sider .ant-menu-submenu > .ant-menu,
.dark-theme .ant-layout-sider .ant-menu-item:active,
.dark-theme .ant-layout-sider .ant-menu-submenu-title:active {
  background-color: transparent;
}
.dark-theme .ant-menu-item-group-title,
.dark-theme .ant-menu-item-selected,
.dark-theme .ant-menu-submenu-selected .ant-menu-submenu-title {
  color: #E0E0E0;
}
.dark-theme .ant-menu-item > span.anticon > svg:not(:hover) {
  fill: #E0E0E0;
}
.dark-theme .ant-layout-sider-dark .layout-sider-scrollbar .ant-menu-item .anticon svg {
  fill: #fff;
}
.dark-theme .ant-layout-sider-dark .layout-sider-scrollbar .ant-menu-item-active .anticon svg {
  fill: #4e5c68;
}
.dark-theme .ant-menu-item:hover,
.dark-theme .ant-menu-item-active,
.dark-theme .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.dark-theme .ant-menu-submenu-active {
  color: #E0E0E0;
}
.dark-theme .ant-menu-item-disabled,
.dark-theme .ant-menu-submenu-disabled {
  color: #a0a0a0 !important;
}
.dark-theme .ant-menu-dark .ant-menu-item-group-title {
  color: #E0E0E0;
}
.dark-theme .ant-menu-dark .ant-menu-item {
  color: #E0E0E0;
}
.dark-theme .ant-menu-dark .ant-menu-item > a {
  color: #ffffff;
}
.dark-theme .ant-menu-dark .ant-menu-item > a:hover {
  color: #d1a3ff;
}
.dark-theme .ant-menu-dark .ant-menu-item-selected > a {
  color: #d1a3ff;
}
.dark-theme .ant-menu-submenu-horizontal > .ant-menu-submenu-title {
  color: #E0E0E0;
}
.dark-theme .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.dark-theme .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.dark-theme .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.dark-theme .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.dark-theme .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.dark-theme .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.dark-theme .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.dark-theme .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  background: #ffffff;
}
.dark-theme .ant-menu-vertical .ant-menu-item::after,
.dark-theme .ant-menu-vertical-left .ant-menu-item::after,
.dark-theme .ant-menu-vertical-right .ant-menu-item::after,
.dark-theme .ant-menu-inline .ant-menu-item::after {
  border-right-color: #08090a;
}
.dark-theme .ant-layout-sider,
.dark-theme .ant-card {
  color: #E0E0E0;
  background: #42505a;
}
.dark-theme .ant-layout-sider-bordered,
.dark-theme .ant-card-bordered {
  border-color: #495862;
}
.dark-theme .ant-layout-sider-head,
.dark-theme .ant-card-head {
  border-color: #495862;
}
.dark-theme .ant-layout-sider-head-title,
.dark-theme .ant-card-head-title {
  color: #E0E0E0;
}
.dark-theme .ant-layout-sider-type-inner .ant-card-head,
.dark-theme .ant-card-type-inner .ant-card-head {
  background: #42505a;
}
.dark-theme .ant-layout-sider-meta-title,
.dark-theme .ant-card-meta-title,
.dark-theme .ant-layout-sider-meta-description,
.dark-theme .ant-card-meta-description {
  color: #E0E0E0;
}
.dark-theme .ant-layout-sider-actions,
.dark-theme .ant-card-actions {
  border-top-color: #495862;
  background: #42505a;
}
.dark-theme .ant-layout-sider-actions > li,
.dark-theme .ant-card-actions > li {
  color: #E0E0E0;
}
.dark-theme .ant-layout-sider-actions > li:not(:last-child),
.dark-theme .ant-card-actions > li:not(:last-child) {
  border-right-color: #495862;
}
.dark-theme .ant-layout-sider-actions > li > span:hover,
.dark-theme .ant-card-actions > li > span:hover {
  color: #e8e8e8;
}
.dark-theme .ant-layout-sider-grid,
.dark-theme .ant-card-grid {
  box-shadow: 1px 0 0 0 #495862, 0 1px 0 0 #495862, 1px 1px 0 0 #495862, 1px 0 0 0 #495862 inset, 0 1px 0 0 #495862 inset;
}
.dark-theme .ant-layout-sider-grid:hover,
.dark-theme .ant-card-grid:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.dark-theme .ant-tabs {
  color: #E0E0E0;
}
.dark-theme .ant-tabs-nav .ant-tabs-tab-active,
.dark-theme .ant-tabs-nav .ant-tabs-tab:hover {
  color: #c7c7c7;
}
.dark-theme .ant-tabs-ink-bar {
  background-color: #c7c7c7;
}
.dark-theme .ant-tabs-bar {
  border-bottom-color: #495862;
}
.dark-theme .ant-tabs-tab-prev,
.dark-theme .ant-tabs-tab-next {
  color: #E0E0E0;
}
.dark-theme .ant-tabs-tab-prev:hover,
.dark-theme .ant-tabs-tab-next:hover {
  color: #ffffff;
}
.dark-theme .ant-tabs-tab-btn-disabled,
.dark-theme .ant-tabs-tab-btn-disabled:hover {
  color: #7a7a7a;
}
.dark-theme .ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: #536572;
  border-bottom-color: #42505a;
  background: #465560;
}
.dark-theme .ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  background: #42505a;
  border-color: #536572;
  border-bottom-color: #42505a;
  color: #ffffff;
}
.dark-theme .ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab .anticon-close {
  color: #E0E0E0;
}
.dark-theme .ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab .anticon-close:hover {
  color: #ffffff;
}
.dark-theme .ant-checkbox-wrapper {
  color: #E0E0E0;
}
.dark-theme .ant-checkbox-disabled + span {
  color: #939393;
}
.dark-theme .ant-checkbox-checked::after,
.dark-theme .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.dark-theme .ant-checkbox:hover .ant-checkbox-inner,
.dark-theme .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #6e8494;
}
.dark-theme .ant-checkbox-checked .ant-checkbox-inner,
.dark-theme .ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #6e8494;
  border-color: #6e8494;
}
.dark-theme .ant-timeline {
  color: #E0E0E0;
}
.dark-theme .ant-timeline-item-head {
  background-color: transparent;
}
.dark-theme .ant-timeline-item-tail {
  border-left-color: #5a6d79;
}
.dark-theme .ant-timeline-item-head-blue {
  border-color: #5a6d79;
  color: #e8e8e8;
}
.dark-theme .ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
.dark-theme .ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
.dark-theme .ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  border-top-color: #42505a;
  border-left-color: #42505a;
}
.dark-theme .ant-popover-inner {
  background-color: #42505a;
}
.dark-theme .ant-popover-arrow {
  background-color: transparent;
}
.dark-theme .ant-popover-arrow-content {
  background-color: transparent;
}
.dark-theme .ant-popover-arrow-content::before {
  background-color: #42505a;
}
.dark-theme .ant-popover-inner {
  -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 0 0 4px rgba(0, 0, 0, 0.28);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.28);
}
.dark-theme .ant-popover-inner-content,
.dark-theme .ant-popover-message {
  color: #E0E0E0;
}
.dark-theme .ant-popover-title {
  border-bottom-color: #495862;
  color: #E0E0E0;
}
.dark-theme .ant-divider {
  color: #E0E0E0;
  background: #495862;
}
.dark-theme .ant-divider.ant-divider-with-text,
.dark-theme .ant-divider.ant-divider-with-text-left,
.dark-theme .ant-divider.ant-divider-with-text-right {
  background: none;
}
.dark-theme .ant-list {
  color: #E0E0E0;
}
.dark-theme .ant-list-split .ant-list-item,
.dark-theme .ant-list-split .ant-list-header,
.dark-theme .ant-list-something-after-last-item .ant-spin-container > .ant-list-item:last-child {
  border-bottom-color: #495862;
}
.dark-theme .ant-list-item-meta-description,
.dark-theme .ant-list-vertical .ant-list-item-content,
.dark-theme .ant-list-item-action > li {
  color: #E0E0E0;
}
.dark-theme .ant-list-bordered {
  border-color: #495862;
}
.dark-theme .ant-list-item-action-split {
  background-color: #495862;
}
.dark-theme .ant-table {
  color: #E0E0E0;
  background: #4d5d69;
}
.dark-theme .ant-table-thead > tr > th {
  background: #4d5d69;
  color: #E0E0E0;
  border-bottom-color: #5a6d79;
}
.dark-theme .ant-table-tbody > tr > td {
  border-bottom-color: #5a6d79;
}
.dark-theme .ant-table-thead > tr.vt-row-hover > td,
.dark-theme .ant-table-tbody > tr.vt-row-hover > td,
.dark-theme .ant-table-thead > tr:hover > td,
.dark-theme .ant-table-tbody > tr:hover > td {
  background: #4d5d69;
}
.dark-theme .ant-table-tbody > tr.ant-table-row-selected td {
  background: #4d5d69;
}
.dark-theme .ant-table-small {
  border-color: #5a6d79;
  background-color: transparent;
}
.dark-theme .ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
.dark-theme .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
.dark-theme .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
.dark-theme .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
.dark-theme .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
.dark-theme .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
.dark-theme .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.dark-theme .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th {
  background: #4d5d69;
  border-bottom-color: #5a6d79;
}
.dark-theme .ant-table.ant-table-bordered .ant-table-title,
.dark-theme .ant-table-bordered .ant-table-header > table,
.dark-theme .ant-table-bordered .ant-table-body > table,
.dark-theme .ant-table-bordered .ant-table-fixed-left table,
.dark-theme .ant-table-bordered .ant-table-fixed-right table,
.dark-theme .ant-table.ant-table-bordered .ant-table-footer,
.dark-theme .ant-table.ant-table-bordered .ant-table-container {
  border-color: #5a6d79;
}
.dark-theme .ant-table-header,
.dark-theme .ant-table-cell-fix-left,
.dark-theme .ant-table-cell-fix-right,
.dark-theme .ant-table-thead th.ant-table-column-has-sorters:hover,
.dark-theme .ant-table-column-sort,
.dark-theme .ant-table-filter-trigger-container-open,
.dark-theme .ant-table .ant-table-filter-trigger-container:hover,
.dark-theme .ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container:hover,
.dark-theme .ant-table-filter-dropdown {
  background: #4d5d69;
  color: #E0E0E0;
}
.dark-theme .ant-table-footer {
  background: #4d5d69;
  color: #E0E0E0;
}
.dark-theme .ant-table-footer:before {
  background: transparent;
}
.dark-theme .ant-table-bordered .ant-table-thead > tr > th,
.dark-theme .ant-table-bordered .ant-table-tbody > tr > td {
  border-right-color: #5a6d79;
}
.dark-theme .ant-table-bordered .ant-table-thead > tr:not(:last-child) > th {
  border-bottom-color: #5a6d79;
}
.dark-theme .ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
  background: #42505a;
}
.dark-theme .ant-table-fixed-left table,
.dark-theme .ant-table-fixed-right table {
  background: #4d5d69;
}
.dark-theme .ant-table-thead > tr > th .anticon-filter,
.dark-theme .ant-table-thead > tr > th .ant-table-filter-icon {
  color: #E0E0E0;
}
.dark-theme .ant-table-thead > tr > th .anticon-filter:hover,
.dark-theme .ant-table-thead > tr > th .ant-table-filter-icon:hover {
  color: #ffffff;
}
.dark-theme .ant-table-column-sorter {
  color: #E0E0E0;
}
.dark-theme .ant-table-column-sorter-up:hover .anticon,
.dark-theme .ant-table-column-sorter-down:hover .anticon,
.dark-theme .ant-table-column-sorter-up.on .anticon-caret-up,
.dark-theme .ant-table-column-sorter-down.on .anticon-caret-up,
.dark-theme .ant-table-column-sorter-up.on .anticon-caret-down,
.dark-theme .ant-table-column-sorter-down.on .anticon-caret-down {
  color: #ffffff;
}
.dark-theme .ant-table-row-expand-icon {
  background: none;
}
.dark-theme .ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container {
  background: #4d5d69;
  color: #E0E0E0;
}
.dark-theme tr.ant-table-expanded-row,
.dark-theme tr.ant-table-expanded-row:hover,
.dark-theme tr.ant-table-expanded-row > td,
.dark-theme tr.ant-table-expanded-row:hover > td {
  background: #4d5d69;
}
.dark-theme .ant-pagination {
  color: #E0E0E0;
}
.dark-theme .ant-pagination-item {
  background-color: #37434b;
  border-color: #495862;
}
.dark-theme .ant-pagination-item-active,
.dark-theme .ant-pagination-item:hover,
.dark-theme .ant-pagination-item:focus {
  border-color: #495862;
}
.dark-theme .ant-pagination-disabled a,
.dark-theme .ant-pagination-disabled:hover a,
.dark-theme .ant-pagination-disabled:focus a,
.dark-theme .ant-pagination-disabled .ant-pagination-item-link,
.dark-theme .ant-pagination-disabled:hover .ant-pagination-item-link,
.dark-theme .ant-pagination-disabled:focus .ant-pagination-item-link {
  color: #6d6d6d;
}
.dark-theme .ant-pagination-prev,
.dark-theme .ant-pagination-next,
.dark-theme .ant-pagination-jump-prev,
.dark-theme .ant-pagination-jump-next {
  color: #E0E0E0;
}
.dark-theme .ant-pagination-prev .ant-pagination-item-link,
.dark-theme .ant-pagination-next .ant-pagination-item-link {
  background-color: #37434b;
  border-color: #495862;
  color: #E0E0E0;
}
.dark-theme .ant-pagination-prev:focus .ant-pagination-item-link,
.dark-theme .ant-pagination-next:focus .ant-pagination-item-link,
.dark-theme .ant-pagination-prev:hover .ant-pagination-item-link,
.dark-theme .ant-pagination-next:hover .ant-pagination-item-link {
  border-color: #495862;
  color: #E0E0E0;
}
.dark-theme .ant-pagination .ant-pagination-item a {
  color: #E0E0E0;
}
.dark-theme .ant-pagination .ant-pagination-item a:hover,
.dark-theme .ant-pagination .ant-pagination-item a:focus {
  color: #E0E0E0;
}
.dark-theme .ant-pagination .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
.dark-theme .ant-pagination .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis,
.dark-theme .ant-pagination .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.dark-theme .ant-pagination .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: #E0E0E0;
}
.dark-theme .ant-pagination-jump-prev:focus:after,
.dark-theme .ant-pagination-jump-next:focus:after,
.dark-theme .ant-pagination-jump-prev:hover:after,
.dark-theme .ant-pagination-jump-next:hover:after {
  color: #E0E0E0;
}
.dark-theme .ant-pagination-simple .ant-pagination-simple-pager input {
  background-color: rgba(121, 121, 121, 0.1);
  border-color: rgba(121, 121, 121, 0.1);
  color: #E0E0E0;
}
.dark-theme .ant-pagination-simple .ant-pagination-simple-pager input:hover {
  border-color: rgba(121, 121, 121, 0.3);
}
.dark-theme .ant-pagination-options-quick-jumper input {
  background-color: rgba(121, 121, 121, 0.1);
  border-color: rgba(121, 121, 121, 0.1);
  color: #E0E0E0;
}
.dark-theme .ant-pagination-options-quick-jumper input:hover {
  border-color: rgba(121, 121, 121, 0.1);
}
.dark-theme .ant-pagination-options-quick-jumper input:focus {
  border-color: #495862;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}
.dark-theme .ant-badge {
  color: #E0E0E0;
}
.dark-theme .ant-badge-status-text {
  color: #E0E0E0;
}
.dark-theme .ant-badge-count {
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15);
}
.dark-theme .ant-cascader-picker:focus .ant-cascader-input {
  border-color: #495862;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}
.dark-theme .ant-cascader-menus {
  background: #37434b;
}
.dark-theme .ant-cascader-menu {
  border-right-color: #495862;
}
.dark-theme .ant-cascader-menu-item:hover {
  background: #313b42;
}
.dark-theme .ant-cascader-picker,
.dark-theme .ant-cascader-picker-clear {
  background-color: transparent;
  color: #E0E0E0;
}
.dark-theme .ant-cascader-picker-arrow,
.dark-theme .ant-cascader-menu-item-expand:after {
  color: #E0E0E0;
}
.dark-theme .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.dark-theme .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  background: #4d5d69;
}
.dark-theme .ant-form {
  color: #E0E0E0;
}
.dark-theme .ant-form-item,
.dark-theme .ant-form-item-label label,
.dark-theme .ant-form-explain,
.dark-theme .ant-form-extra {
  color: #E0E0E0;
}
.dark-theme .ant-picker {
  background-color: rgba(121, 121, 121, 0.1) !important;
  border-color: rgba(121, 121, 121, 0.1);
  color: #E0E0E0;
}
.dark-theme .ant-input {
  background-color: rgba(121, 121, 121, 0.1) !important;
  border-color: rgba(121, 121, 121, 0.1);
  color: #E0E0E0;
}
.dark-theme .ant-input:focus,
.dark-theme .ant-input:hover {
  border-color: rgba(116, 114, 114, 0.1);
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}
.dark-theme .ant-input-number {
  background-color: rgba(121, 121, 121, 0.1);
  border-color: rgba(121, 121, 121, 0.1);
  color: #E0E0E0;
}
.dark-theme .ant-input-number:focus,
.dark-theme .ant-input-number:hover {
  border-color: rgba(116, 114, 114, 0.1);
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}
.dark-theme .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #495862;
}
.dark-theme .ant-input-group-addon {
  background-color: rgba(121, 121, 121, 0.1);
  border-color: rgba(121, 121, 121, 0.1);
  color: #E0E0E0;
}
.dark-theme .ant-input-group-addon .ant-select-open .ant-select-selection,
.dark-theme .ant-input-group-addon .ant-select-focused .ant-select-selection {
  color: #E0E0E0;
}
.dark-theme .ant-input-search-icon {
  color: #E0E0E0;
}
.dark-theme .ant-input-affix-wrapper .ant-input-prefix,
.dark-theme .ant-input-affix-wrapper .ant-input-suffix {
  color: #E0E0E0;
}
.dark-theme .ant-input-affix-wrapper .ant-input-prefix i,
.dark-theme .ant-input-affix-wrapper .ant-input-suffix i,
.dark-theme .ant-input-affix-wrapper .ant-input-prefix .anticon,
.dark-theme .ant-input-affix-wrapper .ant-input-suffix .anticon {
  color: #E0E0E0 !important;
}
.dark-theme .form-background .switch-plan-wrapper {
  background-color: rgba(121, 121, 121, 0.1) !important;
  border-color: rgba(121, 121, 121, 0.1);
  color: #E0E0E0;
}
.dark-theme .form-background .ant-divider {
  background-color: #d9d9d9 !important;
}
.dark-theme .form-background .ant-input-group-addon .ant-select-open .ant-select-selection,
.dark-theme .form-background .ant-input-group-addon .ant-select-focused .ant-select-selection {
  color: #E0E0E0;
}
.dark-theme .form-background .ant-select-multiple .ant-select-selector {
  background-color: rgba(121, 121, 121, 0.1) !important;
  border-color: rgba(121, 121, 121, 0.1);
  color: #E0E0E0;
}
.dark-theme .ant-form-item-explain,
.dark-theme .ant-form-item-extra,
.dark-theme .ant-picker-suffix,
.dark-theme .ant-select-multiple .ant-select-selection-item-remove {
  color: #E0E0E0;
}
.dark-theme .ant-select-multiple .ant-select-selection-item {
  border-color: #E0E0E0;
}
.dark-theme .ant-drawer-header {
  background-color: #434f5a;
  border-bottom-color: #546571;
}
.dark-theme .ant-drawer-title {
  color: #E0E0E0;
}
.dark-theme .ant-modal .ant-typography {
  color: #878787;
}
.dark-theme .ant-modal-content,
.dark-theme .ant-modal-header {
  background-color: #434f5a;
  color: #E0E0E0;
}
.dark-theme .ant-modal-header {
  border-bottom-color: #546571;
}
.dark-theme .ant-modal-footer {
  border-top-color: #546571;
}
.dark-theme .ant-modal-title,
.dark-theme .ant-modal-close,
.dark-theme .ant-modal-confirm-body .ant-modal-confirm-content,
.dark-theme .ant-modal-confirm-body .ant-modal-confirm-title,
.dark-theme .ant-modal .ant-upload-list,
.dark-theme .ant-modal .ant-upload-list-item-card-actions .anticon {
  color: #E0E0E0;
}
.dark-theme .ant-mention-wrapper {
  color: #E0E0E0;
}
.dark-theme .ant-mention-wrapper.disabled .ant-mention-editor {
  background-color: transparent;
  color: #E0E0E0;
}
.dark-theme .ant-mention-wrapper.ant-mention-active:not(.disabled) .ant-mention-editor {
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}
.dark-theme .ant-mention-wrapper .ant-mention-editor {
  background-color: transparent;
  border-color: transparent;
  color: #E0E0E0;
}
.dark-theme .ant-mention-wrapper .ant-mention-editor:focus {
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}
.dark-theme .ant-select {
  color: #E0E0E0;
}
.dark-theme .ant-select-selection {
  background-color: rgba(121, 121, 121, 0.1);
  border-color: rgba(121, 121, 121, 0.1);
}
.dark-theme .ant-select-selection:hover {
  border-color: rgba(121, 121, 121, 0.1);
}
.dark-theme .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background-color: rgba(121, 121, 121, 0.1);
  border-color: rgba(121, 121, 121, 0.1);
}
.dark-theme .ant-select-single:not(.ant-select-customize-input) .ant-select-selector:hover {
  border-color: rgba(121, 121, 121, 0.1);
}
.dark-theme .ant-select-item {
  color: #E0E0E0;
}
.dark-theme .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: rgba(121, 121, 121, 0.1);
  border-color: rgba(121, 121, 121, 0.1);
}
.dark-theme .ant-select-item-option-active:not(.ant-select-item-option-disabled):hover {
  background-color: rgba(121, 121, 121, 0.1);
  border-color: rgba(121, 121, 121, 0.1);
  color: #E0E0E0;
}
.dark-theme .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: rgba(121, 121, 121, 0.1);
  color: #E0E0E0;
}
.dark-theme .ant-select-arrow {
  color: #E0E0E0;
}
.dark-theme .ant-select-focused .ant-select-selection,
.dark-theme .ant-select-selection:focus,
.dark-theme .ant-select-selection:active {
  border-color: rgba(116, 114, 114, 0.1);
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}
.dark-theme .ant-select-dropdown {
  color: #E0E0E0;
  background-color: #37434b;
}
.dark-theme .ant-select-dropdown-menu-item {
  color: #E0E0E0;
}
.dark-theme .ant-select-dropdown-menu-item:hover {
  background-color: #333e45;
}
.dark-theme .ant-select-dropdown-menu-item-selected,
.dark-theme .ant-select-dropdown-menu-item-selected:hover,
.dark-theme .ant-select-dropdown-menu-item-active {
  background-color: #313b42;
  color: #E0E0E0;
}
.dark-theme .ant-select-auto-complete.ant-select .ant-input:focus,
.dark-theme .ant-select-auto-complete.ant-select .ant-input:hover {
  border-color: rgba(121, 121, 121, 0.1);
}
.dark-theme .ant-select-dropdown-menu-item-group-title {
  color: #E0E0E0;
}
.dark-theme .ant-select-auto-complete.ant-select textarea.ant-input {
  background: rgba(121, 121, 121, 0.1) !important;
}
.dark-theme .ant-select-tree,
.dark-theme .ant-select-tree li .ant-select-tree-node-content-wrapper {
  color: #E0E0E0;
}
.dark-theme .ant-select-tree li .ant-select-tree-node-content-wrapper:hover,
.dark-theme .ant-select-tree li .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: #4d5d69;
}
.dark-theme .ant-select-tree-dropdown .ant-select-dropdown-search .ant-select-search__field,
.dark-theme .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner,
.dark-theme .ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner {
  border-color: #4d5d69;
  background-color: #4d5d69;
}
.dark-theme .ant-select-tree {
  background-color: #4d5d69;
}
.dark-theme .ant-select-tree .ant-select-tree-node-content-wrapper:hover,
.dark-theme .ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: #42505a;
}
.dark-theme .ant-select-show-search .ant-select-selection {
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.dark-theme .ant-transfer-list-search-action,
.dark-theme .ant-transfer-list-search-action i {
  color: #E0E0E0;
}
.dark-theme .ant-transfer-list {
  border-color: #536572;
  color: #E0E0E0;
}
.dark-theme .ant-transfer-list-body-with-search {
  background-color: #4d5d69;
}
.dark-theme .ant-transfer-list-header {
  background-color: #4d5d69;
  border-color: #536572;
  color: #E0E0E0;
}
.dark-theme .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  background-color: #536572;
}
.dark-theme .ant-transfer-list-footer {
  border-top-color: #536572;
  background-color: #4d5d69;
}
.dark-theme .ant-transfer-list-content-item-disabled {
  color: #7a7a7a;
}
.dark-theme .ant-calendar {
  background-color: #4d5d69;
  border-color: #4d5d69;
  color: #E0E0E0;
}
.dark-theme .ant-calendar-today .ant-calendar-date {
  border-color: #7c919f;
  color: #7c919f;
  background-color: #627786;
}
.dark-theme .ant-calendar-selected-date .ant-calendar-date,
.dark-theme .ant-calendar-selected-start-date .ant-calendar-date,
.dark-theme .ant-calendar-selected-end-date .ant-calendar-date,
.dark-theme .ant-calendar-selected-day .ant-calendar-date {
  background: #7c919f;
  color: #E0E0E0;
}
.dark-theme .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month:hover,
.dark-theme .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month,
.dark-theme .ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year,
.dark-theme .ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year:hover,
.dark-theme .ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade,
.dark-theme .ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade:hover {
  background: #7c919f;
  color: #E0E0E0;
}
.dark-theme .ant-calendar-month-panel-month:hover,
.dark-theme .ant-calendar-year-panel-year:hover,
.dark-theme .ant-calendar-decade-panel-decade:hover {
  background: #627786;
}
.dark-theme .ant-calendar-input {
  color: #E0E0E0;
  background-color: #4d5d69;
}
.dark-theme .ant-calendar-month-panel,
.dark-theme .ant-calendar-year-panel,
.dark-theme .ant-calendar-decade-panel {
  background-color: #4d5d69;
}
.dark-theme .ant-calendar-header,
.dark-theme .ant-calendar-input-wrap,
.dark-theme .ant-calendar-month-panel-header,
.dark-theme .ant-calendar-year-panel-header,
.dark-theme .ant-calendar-decade-panel-header {
  border-bottom-color: #536572;
}
.dark-theme .ant-calendar-footer,
.dark-theme .ant-calendar-range .ant-calendar-body,
.dark-theme .ant-calendar-range .ant-calendar-month-panel-body,
.dark-theme .ant-calendar-range .ant-calendar-year-panel-body {
  border-top-color: #536572;
}
.dark-theme .ant-calendar-picker-icon,
.dark-theme .ant-calendar-picker-icon:after {
  color: #E0E0E0;
}
.dark-theme .ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: #495862;
}
.dark-theme .ant-calendar-header .ant-calendar-prev-century-btn,
.dark-theme .ant-calendar-header .ant-calendar-next-century-btn,
.dark-theme .ant-calendar-header .ant-calendar-prev-decade-btn,
.dark-theme .ant-calendar-header .ant-calendar-next-decade-btn,
.dark-theme .ant-calendar-header .ant-calendar-prev-month-btn,
.dark-theme .ant-calendar-header .ant-calendar-next-month-btn,
.dark-theme .ant-calendar-header .ant-calendar-prev-year-btn,
.dark-theme .ant-calendar-header .ant-calendar-next-year-btn,
.dark-theme .ant-calendar-header .ant-calendar-century-select,
.dark-theme .ant-calendar-header .ant-calendar-decade-select,
.dark-theme .ant-calendar-header .ant-calendar-year-select,
.dark-theme .ant-calendar-header .ant-calendar-month-select {
  color: #E0E0E0;
}
.dark-theme .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn,
.dark-theme .ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn,
.dark-theme .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn,
.dark-theme .ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn,
.dark-theme .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn,
.dark-theme .ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn,
.dark-theme .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn,
.dark-theme .ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn,
.dark-theme .ant-calendar-month-panel-header .ant-calendar-month-panel-century-select,
.dark-theme .ant-calendar-month-panel-header .ant-calendar-month-panel-decade-select,
.dark-theme .ant-calendar-month-panel-header .ant-calendar-month-panel-year-select,
.dark-theme .ant-calendar-month-panel-header .ant-calendar-month-panel-month-select {
  color: #E0E0E0;
}
.dark-theme .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn,
.dark-theme .ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn,
.dark-theme .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn,
.dark-theme .ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn,
.dark-theme .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn,
.dark-theme .ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn,
.dark-theme .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn,
.dark-theme .ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn,
.dark-theme .ant-calendar-year-panel-header .ant-calendar-year-panel-century-select,
.dark-theme .ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select,
.dark-theme .ant-calendar-year-panel-header .ant-calendar-year-panel-year-select,
.dark-theme .ant-calendar-year-panel-header .ant-calendar-year-panel-month-select {
  color: #E0E0E0;
}
.dark-theme .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn,
.dark-theme .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn,
.dark-theme .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn,
.dark-theme .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn,
.dark-theme .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn,
.dark-theme .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn,
.dark-theme .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn,
.dark-theme .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn {
  color: #E0E0E0;
}
.dark-theme .ant-calendar-date {
  color: #E0E0E0;
}
.dark-theme .ant-calendar-date:hover {
  background: #627786;
}
.dark-theme .ant-calendar-last-month-cell .ant-calendar-date,
.dark-theme .ant-calendar-next-month-btn-day .ant-calendar-date,
.dark-theme .ant-calendar-year-panel-last-decade-cell .ant-calendar-year-panel-year,
.dark-theme .ant-calendar-year-panel-next-decade-cell .ant-calendar-year-panel-year,
.dark-theme .ant-calendar-decade-panel-last-century-cell .ant-calendar-decade-panel-decade,
.dark-theme .ant-calendar-decade-panel-next-century-cell .ant-calendar-decade-panel-decade {
  color: #adadad;
}
.dark-theme .ant-calendar-picker-clear {
  color: #E0E0E0;
  background: none;
}
.dark-theme .ant-calendar .ant-calendar-ok-btn {
  color: #495862 !important;
  background-color: #7c919f !important;
  border-color: #7c919f !important;
}
.dark-theme .ant-calendar .ant-calendar-ok-btn:hover,
.dark-theme .ant-calendar .ant-calendar-ok-btn:focus {
  color: #38424B !important;
  background-color: #9aaab5 !important;
  border-color: #38424B !important;
}
.dark-theme .ant-calendar-range .ant-calendar-in-range-cell:before {
  background-color: #51626f;
}
.dark-theme .ant-calendar-week-number .ant-calendar-body tr.ant-calendar-active-week {
  background: #51626f;
}
.dark-theme .ant-calendar-week-number .ant-calendar-body tr .ant-calendar-selected-day .ant-calendar-date,
.dark-theme .ant-calendar-week-number .ant-calendar-body tr .ant-calendar-selected-day:hover .ant-calendar-date,
.dark-theme .ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn-disabled {
  color: #E0E0E0;
}
.dark-theme .ant-fullcalendar {
  color: #E0E0E0;
  border-top-color: #495862;
}
.dark-theme .ant-fullcalendar-value {
  color: #E0E0E0;
}
.dark-theme .ant-fullcalendar-value:hover {
  background: #4d5d69;
}
.dark-theme .ant-fullcalendar-fullscreen .ant-fullcalendar-month,
.dark-theme .ant-fullcalendar-fullscreen .ant-fullcalendar-date {
  color: #E0E0E0;
  border-top-color: #536572;
}
.dark-theme .ant-fullcalendar-fullscreen .ant-fullcalendar-month:hover,
.dark-theme .ant-fullcalendar-fullscreen .ant-fullcalendar-date:hover,
.dark-theme .ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-month,
.dark-theme .ant-fullcalendar-fullscreen .ant-fullcalendar-selected-day .ant-fullcalendar-date {
  background: #4d5d69;
  color: #E0E0E0;
}
.dark-theme .ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-current-cell .ant-fullcalendar-month,
.dark-theme .ant-fullcalendar-fullscreen .ant-fullcalendar-today .ant-fullcalendar-date {
  border-top-color: #536572;
}
.dark-theme .ant-fullcalendar-fullscreen .ant-fullcalendar-last-month-cell .ant-fullcalendar-date,
.dark-theme .ant-fullcalendar-fullscreen .ant-fullcalendar-next-month-btn-day .ant-fullcalendar-date,
.dark-theme .ant-fullcalendar-last-month-cell .ant-fullcalendar-value,
.dark-theme .ant-fullcalendar-next-month-btn-day .ant-fullcalendar-value {
  color: #adadad;
}
.dark-theme .ant-fullcalendar-selected-day .ant-fullcalendar-value,
.dark-theme .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-value {
  background: #4d5d69;
  color: #E0E0E0;
}
.dark-theme .ant-fullcalendar-today .ant-fullcalendar-value,
.dark-theme .ant-fullcalendar-month-panel-current-cell .ant-fullcalendar-value {
  box-shadow: 0 0 0 1px #7c919f inset;
  background: #7c919f;
}
.dark-theme .ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-value,
.dark-theme .ant-fullcalendar-fullscreen .ant-fullcalendar-selected-day .ant-fullcalendar-value {
  color: #E0E0E0;
  background-color: transparent;
  box-shadow: none;
}
.dark-theme .ant-picker-panel-container {
  background-color: #4d5d69;
  border-color: #4d5d69;
  color: #E0E0E0;
}
.dark-theme .ant-picker-panel,
.dark-theme .ant-picker-panel .ant-picker-footer {
  border-color: #627786;
}
.dark-theme .ant-picker-header {
  border-color: #627786;
  color: #E0E0E0;
}
.dark-theme .ant-picker-header button,
.dark-theme .ant-picker-content th,
.dark-theme .ant-picker-cell,
.dark-theme .ant-picker-input > input {
  color: #E0E0E0;
}
.dark-theme .ant-picker-clear {
  background-color: #4d5d69;
  color: #E0E0E0;
}
.dark-theme .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
  color: #E0E0E0;
  background: #6e8494;
}
.dark-theme .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  color: #E0E0E0;
  background: #627786;
}
.dark-theme .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.dark-theme .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
  background: #6e8494;
}
.dark-theme .ant-picker-calendar,
.dark-theme .ant-picker-calendar-full .ant-picker-panel {
  background-color: transparent;
}
.dark-theme .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
  border-color: #627786;
}
.dark-theme .ant-time-picker-panel,
.dark-theme .ant-time-picker-icon,
.dark-theme .ant-time-picker-icon:after {
  color: #E0E0E0;
}
.dark-theme .ant-time-picker-panel-inner {
  background-color: #4d5d69;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.dark-theme .ant-time-picker-panel-input-wrap {
  border-bottom-color: #536572;
}
.dark-theme .ant-time-picker-panel-input-wrap .ant-time-picker-panel-input {
  background-color: transparent;
}
.dark-theme .ant-time-picker-input {
  background-color: rgba(121, 121, 121, 0.1);
  border-color: rgba(121, 121, 121, 0.1);
  color: #E0E0E0;
}
.dark-theme .ant-time-picker-input:focus,
.dark-theme .ant-time-picker-input:hover {
  border-color: rgba(116, 114, 114, 0.1);
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}
.dark-theme .ant-time-picker-panel-select {
  border-left-color: #536572;
}
.dark-theme .ant-time-picker-panel-select li:hover {
  background: #51626f;
}
.dark-theme li.ant-time-picker-panel-select-option-selected {
  background: #536572;
}
.dark-theme .ant-slider-mark-text {
  color: #7a7a7a;
}
.dark-theme .ant-slider-mark-text-active {
  color: #ffffff;
}
.dark-theme .ant-slider-track {
  background-color: #4d5d69;
}
.dark-theme .ant-slider-active {
  border-color: #4d5d69;
}
.dark-theme .ant-radio {
  color: #E0E0E0;
}
.dark-theme .ant-radio-wrapper {
  color: #E0E0E0;
}
.dark-theme .ant-radio-wrapper:hover .ant-radio .ant-radio-inner,
.dark-theme .ant-radio:hover .ant-radio-inner,
.dark-theme .ant-radio-focused .ant-radio-inner {
  border-color: #6e8494;
}
.dark-theme .ant-radio-checked:after {
  border-color: #6e8494;
}
.dark-theme .ant-radio-checked .ant-radio-inner {
  border-color: #6e8494;
}
.dark-theme .ant-radio-disabled + span {
  color: #E0E0E0;
}
.dark-theme .ant-radio-button-wrapper {
  color: #6e8494;
  background-color: transparent;
  border-color: #6e8494;
}
.dark-theme .ant-radio-button-wrapper:hover,
.dark-theme .ant-radio-button-wrapper:focus {
  color: #E0E0E0;
}
.dark-theme .ant-radio-button-wrapper-checked {
  box-shadow: none;
  border-color: #c6cfd5;
  color: #c6cfd5;
}
.dark-theme .ant-radio-button-wrapper-checked:hover,
.dark-theme .ant-radio-button-wrapper-checked:focus {
  color: #E0E0E0;
}
.dark-theme .ant-radio-button-wrapper-checked:first-child {
  border-color: #c6cfd5;
  color: #c6cfd5;
}
.dark-theme .ant-radio-button-wrapper:not(:first-child):before {
  background-color: #6e8494;
}
.dark-theme .ant-radio-button-wrapper-checked:before {
  background-color: #6e8494 !important;
}
.dark-theme .ant-btn {
  background-color: #37434b;
  border-color: #37434b;
  color: #E0E0E0;
}
.dark-theme .ant-btn:hover,
.dark-theme .ant-btn:focus {
  color: #E0E0E0;
  background-color: #313b42;
  border-color: #313b42;
}
.dark-theme .ant-btn-primary {
  background-color: #313b42 !important;
  border-color: #313b42 !important;
}
.dark-theme .ant-btn-primary:hover,
.dark-theme .ant-btn-primary:focus {
  background-color: #283137 !important;
  border-color: #283137 !important;
}
.dark-theme .ant-btn-primary.disabled,
.dark-theme .ant-btn-primary:disabled {
  background-color: #2c363d !important;
  border-color: #2c363d !important;
}
.dark-theme .ant-btn-primary:not([disabled]):not(.disabled):active,
.dark-theme .ant-btn-primary:not([disabled]):not(.disabled).active {
  background-color: #283137 !important;
  border-color: #283137 !important;
}
.dark-theme .ant-btn-danger {
  color: #f5222d;
  background-color: transparent;
  border-color: #f5222d;
}
.dark-theme .ant-btn-danger:hover,
.dark-theme .ant-btn-danger:focus {
  color: #ffffff;
  background-color: #f5222d;
  border-color: #f5222d;
}
.dark-theme .ant-btn-background-ghost.ant-btn-primary {
  background-color: #313b42 !important;
  border-color: #313b42 !important;
  color: #ffffff !important;
}
.dark-theme .ant-btn-background-ghost.ant-btn-primary:hover,
.dark-theme .ant-btn-background-ghost.ant-btn-primary:focus {
  background-color: #283137 !important;
  border-color: #283137 !important;
  color: #ffffff !important;
}
.dark-theme .ant-upload {
  color: #E0E0E0;
}
.dark-theme .ant-upload.ant-upload-drag {
  background-color: #3c4851;
  border-color: #3c4851;
  color: #E0E0E0;
}
.dark-theme .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: #E0E0E0;
}
.dark-theme .ant-upload.ant-upload-drag p.ant-upload-text,
.dark-theme .ant-upload.ant-upload-drag p.ant-upload-hint {
  color: #E0E0E0;
}
.dark-theme .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #495862;
}
.dark-theme .ant-upload-list-item a,
.dark-theme .ant-upload-list-item-info .anticon-loading,
.dark-theme .ant-upload-list-item-info .anticon-paper-clip {
  color: #E0E0E0;
}
.dark-theme .ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: #4d5d69;
}
.dark-theme .ant-upload.ant-upload-select-picture-card {
  background-color: #3c4851;
  border-color: #3c4851;
}
.dark-theme .ant-upload.ant-upload-select-picture-card:hover {
  border-color: #495862;
}
.dark-theme .ant-switch {
  color: #ffffff;
}
.dark-theme .ant-switch-checked {
  background-color: #37434b;
}
.dark-theme .ant-breadcrumb {
  color: #bfbfbf;
}
.dark-theme .ant-breadcrumb li:last-child span {
  color: #bfbfbf;
}
.dark-theme .ant-breadcrumb-separator,
.dark-theme .ant-breadcrumb > span:last-child {
  color: #bfbfbf;
}
.dark-theme .ant-dropdown {
  color: #E0E0E0;
}
.dark-theme .ant-dropdown-menu-item-selected,
.dark-theme .ant-dropdown-menu-submenu-title-selected,
.dark-theme .ant-dropdown-menu-item-selected > a,
.dark-theme .ant-dropdown-menu-submenu-title-selected > a {
  background-color: #3c4851;
  color: #E0E0E0;
}
.dark-theme .ant-dropdown-menu {
  background-color: #313b42;
}
.dark-theme .ant-dropdown-menu-item,
.dark-theme .ant-dropdown-menu-submenu-title {
  color: #E0E0E0;
}
.dark-theme .ant-dropdown-menu-item .menu-link-button,
.dark-theme .ant-dropdown-menu-submenu-title .menu-link-button {
  padding: 0;
  background-color: transparent;
  border-color: transparent;
}
.dark-theme .ant-dropdown-menu-item-divider {
  background-color: #3c4851;
}
.dark-theme .ant-dropdown-menu-item:hover,
.dark-theme .ant-dropdown-menu-submenu-title:hover {
  background-color: #465560;
}
.dark-theme .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow:after,
.dark-theme .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow:after {
  color: #E0E0E0;
}
.dark-theme .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title,
.dark-theme .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow::after {
  color: #adadad;
}
.dark-theme .ant-steps-item {
  color: #E0E0E0;
}
.dark-theme .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #42505a;
  border-color: #E0E0E0;
}
.dark-theme .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #E0E0E0;
}
.dark-theme .ant-steps-item-process .ant-steps-item-icon {
  background-color: #4d5d69;
  border-color: #4d5d69;
}
.dark-theme .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #E0E0E0;
}
.dark-theme .ant-steps-item-wait .ant-steps-item-icon {
  background-color: #42505a;
  border-color: #586a77;
}
.dark-theme .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: #586a77;
}
.dark-theme .ant-steps-item-finish > .ant-steps-item-content > .ant-steps-item-description,
.dark-theme .ant-steps-item-process > .ant-steps-item-content > .ant-steps-item-description,
.dark-theme .ant-steps-item-wait > .ant-steps-item-content > .ant-steps-item-description {
  color: #adadad;
}
.dark-theme .ant-steps-item-finish > .ant-steps-item-content > .ant-steps-item-title,
.dark-theme .ant-steps-item-process > .ant-steps-item-content > .ant-steps-item-title,
.dark-theme .ant-steps-item-wait > .ant-steps-item-content > .ant-steps-item-title {
  color: #E0E0E0;
}
.dark-theme .ant-steps-item-process > .ant-steps-item-content > .ant-steps-item-title:after,
.dark-theme .ant-steps-item-wait > .ant-steps-item-content > .ant-steps-item-title:after,
.dark-theme .ant-steps-item-process > .ant-steps-item-tail:after,
.dark-theme .ant-steps-item-error > .ant-steps-item-content > .ant-steps-item-title:after {
  background-color: #495862;
}
.dark-theme .ant-steps-item-finish > .ant-steps-item-content > .ant-steps-item-title:after,
.dark-theme .ant-steps-item-finish > .ant-steps-item-tail:after {
  background-color: #E0E0E0;
}
.dark-theme .ant-steps-item-error .ant-steps-item-icon {
  background-color: #42505a;
}
.dark-theme .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot,
.dark-theme .ant-steps-item-finish > .ant-steps-item-tail:after,
.dark-theme .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #22282e;
}
.dark-theme .ant-steps-item-process > .ant-steps-item-tail:after,
.dark-theme .ant-steps-item-wait > .ant-steps-item-tail:after {
  background-color: #536572;
}
.dark-theme .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #536572;
}
.dark-theme .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title,
.dark-theme .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description,
.dark-theme .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title,
.dark-theme .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title,
.dark-theme .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description,
.dark-theme .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: #E0E0E0;
}
.dark-theme .steps-content {
  border-color: #495862;
  background-color: #4d5d69;
}
.dark-theme .ant-input-affix-wrapper {
  color: #E0E0E0;
  border-color: #495862;
  background-color: rgba(121, 121, 121, 0.1) !important;
}
.dark-theme .ant-input-affix-wrapper .ant-input {
  background-color: transparent !important;
}
.dark-theme .ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.dark-theme .ant-cascader-menu-item-loading-icon {
  color: #E0E0E0;
}
.dark-theme .ant-cascader-menu-item-disabled {
  color: #d3d3d3;
}
.dark-theme .ant-picker-week-panel-row:hover td {
  background-color: #4d5d69;
  color: #dddddd;
}
.dark-theme .ant-picker-cell-disabled::before {
  background-color: #4d5d69;
}
.dark-theme .ant-picker-cell-disabled .ant-picker-cell-inner {
  color: #E0E0E0;
  background-color: #38424B;
}
.dark-theme .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.dark-theme .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.dark-theme .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: #37434b;
}
.dark-theme .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date,
.dark-theme .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date,
.dark-theme .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today,
.dark-theme .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today {
  background-color: #3e4b54;
}
.dark-theme .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.dark-theme .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.dark-theme .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background-color: #586a77;
}
.dark-theme .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
.dark-theme .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  background-color: #4d5d69;
}
.dark-theme .ant-alert-message,
.dark-theme .ant-anchor,
.dark-theme .ant-anchor-link-title {
  color: #E0E0E0;
}
.dark-theme .ant-alert-info,
.dark-theme .ant-alert-warning,
.dark-theme .ant-alert-success,
.dark-theme .ant-alert-error {
  background-color: #4e5c68 !important;
}
.dark-theme .ant-alert-info {
  border-color: #7f00ff;
}
.dark-theme .ant-alert-info .ant-alert-icon {
  color: #e0e0e0;
}
.dark-theme .ant-tree-select-dropdown .ant-select-tree .ant-select-tree-treenode {
  background-color: #36434b;
}
.dark-theme .ant-picker-calendar .ant-picker-panel {
  background-color: #4d5d69;
}
.dark-theme .ant-collapse {
  background-color: #4d5d69;
  border-color: #586a77;
  color: #E0E0E0;
}
.dark-theme .ant-collapse > .ant-collapse-item {
  border-color: #586a77;
}
.dark-theme .ant-collapse .ant-collapse {
  border-color: #738999;
}
.dark-theme .ant-collapse .ant-collapse > .ant-collapse-item {
  border-color: #738999;
}
.dark-theme .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: #E0E0E0;
}
.dark-theme .ant-collapse-content {
  color: #E0E0E0;
  background-color: #5c6f7d;
  border-top-color: #586a77;
}
.dark-theme .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header,
.dark-theme .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
  color: #7a7a7a;
}
.dark-theme .ant-carousel {
  color: #E0E0E0;
}
.dark-theme .ant-carousel .slick-slide {
  background-color: #4d5d69;
}
.dark-theme .ant-tree {
  color: #E0E0E0;
  background-color: #4d5d69;
}
.dark-theme .ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper {
  color: #ededed;
}
.dark-theme .ant-tree li .ant-tree-node-content-wrapper {
  color: #E0E0E0;
}
.dark-theme .ant-tree li.ant-tree-treenode-disabled > span:not(.ant-tree-switcher),
.dark-theme .ant-tree li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper,
.dark-theme .ant-tree li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper span {
  color: #7a7a7a;
}
.dark-theme .ant-tree li .ant-tree-node-content-wrapper:hover,
.dark-theme .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #4d5d69;
}
.dark-theme .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
.dark-theme .ant-tree-checkbox:hover .ant-tree-checkbox-inner,
.dark-theme .ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
  border-color: #37434b;
}
.dark-theme .ant-tree-checkbox-checked .ant-tree-checkbox-inner,
.dark-theme .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
  background-color: #37434b;
  border-color: #37434b !important;
  color: #E0E0E0;
}
.dark-theme .ant-tree-show-line .ant-tree-switcher {
  background-color: #37434b;
}
.dark-theme .ant-tree .ant-tree-node-content-wrapper:hover,
.dark-theme .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #37434b;
  color: #E0E0E0;
}
.dark-theme .ant-tree.ant-tree-show-line li span.ant-tree-switcher {
  background: transparent;
  color: #E0E0E0;
}
.dark-theme .ant-tag-checkable {
  color: #E0E0E0;
}
.dark-theme .ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: #ffffff;
}
.dark-theme .ant-progress-text,
.dark-theme .ant-progress-circle .ant-progress-text {
  color: #E0E0E0;
}
.dark-theme .ant-anchor-wrapper {
  background-color: transparent;
}
.dark-theme .ant-anchor-link-active > .ant-anchor-link-title {
  color: #c7c7c7;
}
.dark-theme .rdw-editor-wrapper {
  color: #474747;
  background-color: #ffffff;
}
.dark-theme .rdw-editor-wrapper + textarea {
  color: #474747;
}
.dark-theme .rbc-event {
  background-color: #6e8494;
}
.dark-theme .rbc-event.rbc-selected {
  background-color: #2c363d;
}
.dark-theme .rbc-slot-selection {
  background-color: #586a77;
}
.dark-theme .rbc-toolbar button {
  color: #E0E0E0;
  border-color: #E0E0E0;
}
.dark-theme .rbc-toolbar button:active,
.dark-theme .rbc-toolbar button.rbc-active,
.dark-theme .rbc-toolbar button:active:hover,
.dark-theme .rbc-toolbar button.rbc-active:hover,
.dark-theme .rbc-toolbar button:active:focus,
.dark-theme .rbc-toolbar button.rbc-active:focus,
.dark-theme .rbc-toolbar button:focus,
.dark-theme .rbc-toolbar button:hover {
  color: #38424B;
  background-color: #E0E0E0;
  border-color: #E0E0E0;
}
.dark-theme .rbc-month-view,
.dark-theme .rbc-time-view,
.dark-theme .rbc-today {
  background: #42505a;
}
.dark-theme .ais-Breadcrumb,
.dark-theme .ais-ClearRefinements,
.dark-theme .ais-CurrentRefinements,
.dark-theme .ais-GeoSearch,
.dark-theme .ais-HierarchicalMenu,
.dark-theme .ais-Hits,
.dark-theme .ais-Results,
.dark-theme .ais-HitsPerPage,
.dark-theme .ais-ResultsPerPage,
.dark-theme .ais-InfiniteHits,
.dark-theme .ais-InfiniteResults,
.dark-theme .ais-Menu,
.dark-theme .ais-MenuSelect,
.dark-theme .ais-NumericMenu,
.dark-theme .ais-NumericSelector,
.dark-theme .ais-Pagination,
.dark-theme .ais-Panel,
.dark-theme .ais-PoweredBy,
.dark-theme .ais-RangeInput,
.dark-theme .ais-RangeSlider,
.dark-theme .ais-RatingMenu,
.dark-theme .ais-RefinementList,
.dark-theme .ais-RefinementList-count,
.dark-theme .ais-SearchBox,
.dark-theme .ais-SortBy,
.dark-theme .ais-Stats,
.dark-theme .ais-ToggleRefinement,
.dark-theme .ais-RatingMenu-count,
.dark-theme .ais-SearchBox-input,
.dark-theme .ais-RangeInput-input,
.dark-theme .ais-MenuSelect-select,
.dark-theme .ais-NumericSelector-select,
.dark-theme .ais-HitsPerPage-select,
.dark-theme .ais-ResultsPerPage-select,
.dark-theme .ais-SortBy-select,
.dark-theme .ais-RangeInput-submit,
.dark-theme .ais-ClearRefinements-button,
.dark-theme .ais-CurrentRefinements-reset,
.dark-theme .ais-GeoSearch-redo,
.dark-theme .ais-GeoSearch-reset,
.dark-theme .ais-HierarchicalMenu-showMore,
.dark-theme .ais-InfiniteHits-loadMore,
.dark-theme .ais-InfiniteResults-loadMore,
.dark-theme .ais-Menu-showMore,
.dark-theme .ais-RefinementList-showMore {
  background-color: #E0E0E0;
  color: #38424B;
}
.dark-theme .ais-Breadcrumb:focus,
.dark-theme .ais-ClearRefinements:focus,
.dark-theme .ais-CurrentRefinements:focus,
.dark-theme .ais-GeoSearch:focus,
.dark-theme .ais-HierarchicalMenu:focus,
.dark-theme .ais-Hits:focus,
.dark-theme .ais-Results:focus,
.dark-theme .ais-HitsPerPage:focus,
.dark-theme .ais-ResultsPerPage:focus,
.dark-theme .ais-InfiniteHits:focus,
.dark-theme .ais-InfiniteResults:focus,
.dark-theme .ais-Menu:focus,
.dark-theme .ais-MenuSelect:focus,
.dark-theme .ais-NumericMenu:focus,
.dark-theme .ais-NumericSelector:focus,
.dark-theme .ais-Pagination:focus,
.dark-theme .ais-Panel:focus,
.dark-theme .ais-PoweredBy:focus,
.dark-theme .ais-RangeInput:focus,
.dark-theme .ais-RangeSlider:focus,
.dark-theme .ais-RatingMenu:focus,
.dark-theme .ais-RefinementList:focus,
.dark-theme .ais-RefinementList-count:focus,
.dark-theme .ais-SearchBox:focus,
.dark-theme .ais-SortBy:focus,
.dark-theme .ais-Stats:focus,
.dark-theme .ais-ToggleRefinement:focus,
.dark-theme .ais-RatingMenu-count:focus,
.dark-theme .ais-SearchBox-input:focus,
.dark-theme .ais-RangeInput-input:focus,
.dark-theme .ais-MenuSelect-select:focus,
.dark-theme .ais-NumericSelector-select:focus,
.dark-theme .ais-HitsPerPage-select:focus,
.dark-theme .ais-ResultsPerPage-select:focus,
.dark-theme .ais-SortBy-select:focus,
.dark-theme .ais-RangeInput-submit:focus,
.dark-theme .ais-ClearRefinements-button:focus,
.dark-theme .ais-CurrentRefinements-reset:focus,
.dark-theme .ais-GeoSearch-redo:focus,
.dark-theme .ais-GeoSearch-reset:focus,
.dark-theme .ais-HierarchicalMenu-showMore:focus,
.dark-theme .ais-InfiniteHits-loadMore:focus,
.dark-theme .ais-InfiniteResults-loadMore:focus,
.dark-theme .ais-Menu-showMore:focus,
.dark-theme .ais-RefinementList-showMore:focus,
.dark-theme .ais-Breadcrumb:hover,
.dark-theme .ais-ClearRefinements:hover,
.dark-theme .ais-CurrentRefinements:hover,
.dark-theme .ais-GeoSearch:hover,
.dark-theme .ais-HierarchicalMenu:hover,
.dark-theme .ais-Hits:hover,
.dark-theme .ais-Results:hover,
.dark-theme .ais-HitsPerPage:hover,
.dark-theme .ais-ResultsPerPage:hover,
.dark-theme .ais-InfiniteHits:hover,
.dark-theme .ais-InfiniteResults:hover,
.dark-theme .ais-Menu:hover,
.dark-theme .ais-MenuSelect:hover,
.dark-theme .ais-NumericMenu:hover,
.dark-theme .ais-NumericSelector:hover,
.dark-theme .ais-Pagination:hover,
.dark-theme .ais-Panel:hover,
.dark-theme .ais-PoweredBy:hover,
.dark-theme .ais-RangeInput:hover,
.dark-theme .ais-RangeSlider:hover,
.dark-theme .ais-RatingMenu:hover,
.dark-theme .ais-RefinementList:hover,
.dark-theme .ais-RefinementList-count:hover,
.dark-theme .ais-SearchBox:hover,
.dark-theme .ais-SortBy:hover,
.dark-theme .ais-Stats:hover,
.dark-theme .ais-ToggleRefinement:hover,
.dark-theme .ais-RatingMenu-count:hover,
.dark-theme .ais-SearchBox-input:hover,
.dark-theme .ais-RangeInput-input:hover,
.dark-theme .ais-MenuSelect-select:hover,
.dark-theme .ais-NumericSelector-select:hover,
.dark-theme .ais-HitsPerPage-select:hover,
.dark-theme .ais-ResultsPerPage-select:hover,
.dark-theme .ais-SortBy-select:hover,
.dark-theme .ais-RangeInput-submit:hover,
.dark-theme .ais-ClearRefinements-button:hover,
.dark-theme .ais-CurrentRefinements-reset:hover,
.dark-theme .ais-GeoSearch-redo:hover,
.dark-theme .ais-GeoSearch-reset:hover,
.dark-theme .ais-HierarchicalMenu-showMore:hover,
.dark-theme .ais-InfiniteHits-loadMore:hover,
.dark-theme .ais-InfiniteResults-loadMore:hover,
.dark-theme .ais-Menu-showMore:hover,
.dark-theme .ais-RefinementList-showMore:hover {
  background-color: #E0E0E0;
  color: #38424B;
}
.dark-theme .ais-Breadcrumb-link,
.dark-theme .ais-HierarchicalMenu-link,
.dark-theme .ais-Menu-link,
.dark-theme .ais-Pagination-link,
.dark-theme .ais-RatingMenu-link {
  color: #E0E0E0;
}
.dark-theme .ais-Breadcrumb-link:focus,
.dark-theme .ais-HierarchicalMenu-link:focus,
.dark-theme .ais-Menu-link:focus,
.dark-theme .ais-Pagination-link:focus,
.dark-theme .ais-RatingMenu-link:focus,
.dark-theme .ais-Breadcrumb-link:hover,
.dark-theme .ais-HierarchicalMenu-link:hover,
.dark-theme .ais-Menu-link:hover,
.dark-theme .ais-Pagination-link:hover,
.dark-theme .ais-RatingMenu-link:hover {
  color: #E0E0E0;
  background-color: #38424B;
}
.dark-theme .ais-Pagination-item--selected .ais-Pagination-link {
  background-color: #7f00ff;
  border-color: #495862;
}
.dark-theme .ant-dropdown-menu-item > a,
.dark-theme .ant-dropdown-menu-submenu-title > a {
  color: #E0E0E0;
}
.dark-theme .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title,
.dark-theme .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  color: #E0E0E0;
  background-color: #38424b;
}
.dark-theme .ant-dropdown-menu-item > .anticon:first-child,
.dark-theme .ant-dropdown-menu-submenu-title > .anticon:first-child,
.dark-theme .ant-dropdown-menu-item > span > .anticon:first-child,
.dark-theme .ant-dropdown-menu-submenu-title > span > .anticon:first-child {
  color: #E0E0E0;
}
.dark-theme .btn-primary {
  background-color: #00d9c9 !important;
  border-color: #00d9c9 !important;
}
.dark-theme .btn-primary:hover,
.dark-theme .btn-primary:focus {
  background-color: #006d75 !important;
  border-color: #006d75 !important;
}
.dark-theme .btn-primary.disabled,
.dark-theme .btn-primary:disabled {
  background-color: #00d9c9 !important;
  border-color: #00d9c9 !important;
}
.dark-theme .btn-primary:not([disabled]):not(.disabled):active,
.dark-theme .btn-primary:not([disabled]):not(.disabled).active {
  background-color: #006d75 !important;
  border-color: #006d75 !important;
}
.dark-theme .user-profile-page .quota .lock .quota-card-lock div {
  background-color: transparent;
  border-color: transparent;
  color: #fff;
}
.dark-theme .feedback-icon {
  color: #d1a3ff;
}
.dark-theme .ant-select:hover .ant-select-clear {
  background: #42505A;
}
.dark-theme .ant-checkbox .ant-checkbox-inner {
  background-color: #7979791a;
  border-color: #7979791a;
}
.dark-theme .vt-previous-month-btn:hover,
.dark-theme .vt-next-month-btn:hover {
  color: #bf80ff !important;
  border-color: #bf80ff !important;
}
.dark-theme .ant-notification-notice-info .ant-notification-notice-icon,
.dark-theme .ant-typography-copy,
.dark-theme .anticon-check-circle {
  color: #bf80ff !important;
}
.dark-theme .ant-btn-primary {
  box-shadow: none;
}
.dark-theme .vt-approvers-name {
  color: #d1a3ff;
}
.dark-theme .vt-back-btn:hover {
  color: #fff !important;
  border-color: #fff !important;
}
body.dark-theme .microsoft-saas-create-company .card-box {
  background-color: #4e5c68;
  border-color: #38424B;
}
body.dark-theme .microsoft-saas-create-company .card-box .link-icon {
  color: #bf80ff;
}
body.dark-theme .microsoft-saas-create-company .card-box.completed {
  border-color: #4e5c68;
  background-color: #38424B;
}
body.dark-theme .microsoft-saas-create-company .card-box.completed .link-right {
  color: #d1a3ff;
}
body.dark-theme .microsoft-saas-create-company .card-box.completed .link-right .link-icon {
  color: #d1a3ff;
}
body.dark-theme .microsoft-saas-create-company .card-box.completed .link-right .ant-typography {
  color: #d1a3ff;
}
body.dark-theme #chakra-toast-manager-bottom-left .chakra-alert {
  background-color: darkred!important;
}
